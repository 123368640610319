import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NavBarComp from '../components/NavBarComp';

const CompanyMain = props => {
	let { uid } = useSelector(state => state.authUser);
	const history = useHistory();

	if (!uid) {
		history.push('/');
	}
	return (
		<>
			<NavBarComp />
			{props.children}
			{/* <footer>Main Footer</footer> */}
		</>
	);
};
export default CompanyMain;
