import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { getAllUnreadMsgs } from '../store/actions/companyChatAction';
import { fetchMyCloseJobs, fetchMyJobs } from '../store/actions/jobAction';

const CompanyDashboard = () => {
	let dispatch = useDispatch();
	const { myJobs, closeJobs } = useSelector(state => state.jobs);
	const { uid } = useSelector(state => state.authUser);
	const { unReadMessages } = useSelector(state => state.companyChat);

	useEffect(() => {
		dispatch(fetchMyJobs(uid));
		dispatch(fetchMyCloseJobs(uid));
	}, []);

	useEffect(() => {
		dispatch(getAllUnreadMsgs(uid));
	}, []);

	return (
		<div className='mt-4 cDashboardMain'>
			<Container fluid>
				<Row>
					<Col lg='6' xl='4'>
						<Card className='mb-4 mb-xl-0'>
							<CardBody>
								<div className='d-flex align-items-center justify-content-between'>
									<div>
										<CardTitle
											tag='h5'
											className='text-uppercase text-muted mb-0'
										>
											Your Jobs ({myJobs.length})
										</CardTitle>
									</div>
									<div>
										<div className='icon-shape bg-info text-white'>
											<i className='fas fa-briefcase'></i>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col lg='6' xl='4'>
						<Card className='mb-4 mb-xl-0'>
							<CardBody>
								<div className='d-flex align-items-center justify-content-between'>
									<div>
										<CardTitle
											tag='h5'
											className='text-uppercase text-muted mb-0'
										>
											New Messages (
											{unReadMessages?.length})
										</CardTitle>
									</div>
									<div>
										<div className='icon-shape bg-success text-white'>
											<i className='fas fa-envelope'></i>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col lg='6' xl='4'>
						<Card className='mb-4 mb-xl-0'>
							<CardBody>
								<div className='d-flex align-items-center justify-content-between'>
									<div>
										<CardTitle
											tag='h5'
											className='text-uppercase text-muted mb-0'
										>
											closed Jobs ({closeJobs.length})
										</CardTitle>
									</div>
									<div>
										<div className='icon-shape bg-danger text-white'>
											<i className='fas fa-times'></i>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default CompanyDashboard;
