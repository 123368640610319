import React from 'react';

const MyJobsCard = ({ ele }) => {
	return (
		<>
			<h4 className='mb-3'>{ele.title}</h4>

			<p
				className='text-justify mb-2'
				style={{
					fontWeight: '500',
				}}
			>
				{ele.description}
			</p>
			<p
				className='mb-2'
				style={{
					fontWeight: '500',
				}}
			>
				{ele.hourly_pay}$
			</p>
			<p
				className='mb-2'
				style={{
					fontWeight: '500',
				}}
			>
				{ele.type}
			</p>
			<p
				className='mb-0'
				style={{
					fontWeight: '500',
				}}
			>
				{ele.state},{ele.city}
			</p>
		</>
	);
};

export default MyJobsCard;
