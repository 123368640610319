import firebase from '../../config/firebase';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

export const chatLoader = val => async dispatch => {
	dispatch({ type: 'CHAT_LOADING', payload: val });
};

export const createChat = (chatObj, msgObj) => async dispatch => {
	dispatch(chatLoader(true));
	const Chat_id = uuidv4();
	let imgURL;
	if (msgObj.messageFile) {
		let customImgKey = uuidv4();
		var imgName = msgObj.messageFile.name;
		let ext2 = imgName.slice(imgName.lastIndexOf('.'));
		var imgLets = await firebase
			.storage()
			.ref('user_files/' + customImgKey + ext2.toLowerCase())
			.put(msgObj.messageFile);
		imgURL = await imgLets.ref.getDownloadURL();
	}
	let newMsgObj = {
		message: msgObj.message,
		sender_id: msgObj.sender_id,
		messageFile_url: msgObj.messageFile ? imgURL : null,
		read: msgObj.read,
		created_at: firebase.firestore.Timestamp.now(),
	};

	await firebase
		.firestore()
		.collection('M4F_chats')
		.doc(Chat_id)
		.set(chatObj)
		.then(() => {
			dispatch(chatLoader(false));
			firebase
				.firestore()
				.collection('M4F_chats')
				.doc(Chat_id)
				.collection('M4F_messages')
				.add(newMsgObj);
			// .then(() => {
			// 	firebase
			// 		.firestore()
			// 		.collection('notifications')
			// 		.add(chatNotificationObj);
			// })
			// .catch(err => {
			// 	toast.warning(err.message);
			// });
		})
		.catch(err => {
			toast.warning(err.message);
			dispatch(chatLoader(false));
		});
};

export const createMessage = (id, msgObj) => async dispatch => {
	dispatch(chatLoader(true));

	let imgURL;
	if (msgObj.messageFile) {
		let customImgKey = uuidv4();
		var imgName = msgObj.messageFile.name;
		let ext2 = imgName.slice(imgName.lastIndexOf('.'));
		var imgLets = await firebase
			.storage()
			.ref('user_files/' + customImgKey + ext2.toLowerCase())
			.put(msgObj.messageFile);
		imgURL = await imgLets.ref.getDownloadURL();
	}
	let newMsgObj = {
		message: msgObj.message,
		sender_id: msgObj.sender_id,
		messageFile_url: msgObj.messageFile ? imgURL : null,
		read: msgObj.read,
		created_at: firebase.firestore.Timestamp.now(),
	};
	firebase
		.firestore()
		.collection('M4F_chats')
		.doc(id)
		.collection('M4F_messages')
		.add(newMsgObj)
		.then(() => {
			dispatch(chatLoader(false));
			// firebase
			// 	.firestore()
			// 	.collection('notifications')
			// 	.add(chatNotificationObj);
		})
		.catch(err => {
			toast.warning(err.message);
			dispatch(chatLoader(false));
		});
};

export const getAllChatsById = id => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_chats')
		.where('participants', 'array-contains', id)
		.orderBy('created_at', 'desc')
		.onSnapshot(async query => {
			var tempChat = [];
			for await (let doc of query.docs) {
				var unReadMsg = [];
				let participant = doc
					.data()
					.participants.find(ele => ele != id);
				let userRef = await firebase
					.firestore()
					.collection('MF4_users')
					.doc(participant)
					.get();
				let newMessage = await firebase
					.firestore()
					.collection('M4F_chats')
					.doc(doc.id)
					.collection('M4F_messages')
					.where('read', '==', false)
					.where('sender_id', '!=', id)
					.get();
				newMessage.docs.forEach(doc => {
					unReadMsg.push(doc.data());
				});
				// console.log(unReadMsg);
				tempChat.push({
					chatUser: userRef.data(),
					unreadMsg: unReadMsg,
					partId: participant,
					id: doc.id,
					...doc.data(),
				});
			}
			dispatch({
				type: 'GET_ALL_CHAT_BY_ID',
				payload: tempChat,
			});
		});
};

export const getAllUnreadMsgs = id => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_chats')
		.where('participants', 'array-contains', id)
		.onSnapshot(async query => {
			var tempMSg = [];
			for await (let doc of query.docs) {
				let newMessage = await firebase
					.firestore()
					.collection('M4F_chats')
					.doc(doc.id)
					.collection('M4F_messages')
					.where('read', '==', false)
					.get();
				newMessage.docs.forEach(doc => {
					if (doc.data().sender_id != id) {
						tempMSg.push(doc.data());
					}
				});
			}
			dispatch({
				type: 'GET_ALL_UNREAD_MESSAGE',
				payload: tempMSg,
			});
		});
};

export const getAllIndividualChatsById = id => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_chats')
		.where('participants', 'array-contains', id)
		.orderBy('created_at', 'desc')
		.onSnapshot(async query => {
			var tempChat = [];
			for await (let doc of query.docs) {
				let participant = doc
					.data()
					.participants.find(ele => ele != id);
				let userRef = await firebase
					.firestore()
					.collection('company_users')
					.doc(participant)
					.get();
				tempChat.push({
					chatUser: userRef.data(),
					partId: participant,
					id: doc.id,
					...doc.data(),
				});
			}
			dispatch({
				type: 'GET_ALL_INDIVIDUAL_CHAT_BY_ID',
				payload: tempChat,
			});
		});
};

export const getMessageById = id => async dispatch => {
	let unSub = firebase
		.firestore()
		.collection('M4F_chats')
		.doc(id)
		.collection('M4F_messages')
		.orderBy('created_at', 'asc')
		.onSnapshot(query => {
			var tempMSg = [];
			query.forEach(doc => {
				tempMSg.push({ id: doc.id, ...doc.data() });
			});
			dispatch({
				type: 'GET_ALL_MESSAGES_BY_ID',
				payload: tempMSg,
			});
		});
	dispatch({
		type: 'SET_UNSUB',
		payload: unSub,
	});
};
export const allMessageRead = id => async dispatch => {
	let tempChat = [];
	let messages = await firebase
		.firestore()
		.collection('M4F_chats')
		.doc(id)
		.collection('M4F_messages')
		.get();
	messages.forEach(msg => {
		tempChat.push({ id: msg.id, ...msg.data() });
	});
	for await (let doc of tempChat) {
		firebase
			.firestore()
			.collection('M4F_chats')
			.doc(id)
			.collection('MF4_messages')
			.doc(doc.id)
			.update({ read: true });
	}
};

export const cleanSubscribeChats = () => async dispatch => {
	dispatch({
		type: 'SET_UNSUB',
		payload: null,
	});
};
