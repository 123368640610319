import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import firebase from '../config/firebase';

import {
	Button,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Spinner,
} from 'reactstrap';
import { createNewJob } from '../store/actions/jobAction';

const NewJob = () => {
	const dispatch = useDispatch();

	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [type, setType] = useState('');
	const [pay, setPay] = useState('');
	const [startingDate, setStartingDate] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [skills, setSkills] = useState([]);

	const { uid } = useSelector(state => state.authUser);
	const { loading } = useSelector(state => state.jobs);
	const defaultSKillsProps = {
		options: skills.map(insti => {
			return insti;
		}),
		getOptionLabel: option => option,
	};
	return (
		<Container fluid>
			<div className='mt-4 px-5 Formcard'>
				<h3 className='text-center mb-2 pt-2'>
					<span style={{ borderBottom: '3px dashed black' }}>
						JOB FORM
					</span>
				</h3>

				<Form
					onSubmit={e => {
						e.preventDefault();
						let obj = {
							title: title,
							description: description,
							hourly_pay: pay,
							starting_from: startingDate,
							type: type,
							state: state.toUpperCase(),
							city: city.toUpperCase(),
							created_at: firebase.firestore.Timestamp.now(),
							creator_Id: uid,
							skills: skills,
							status: 'active',
							selected_canidate: [],
						};
						dispatch(createNewJob(obj));
						setTitle('');
						setDescription('');
						setStartingDate('');
						setPay('');
						setType('');
						setState('');
						setCity('');
						setSkills([]);
					}}
				>
					<FormGroup>
						<Label className='labelText' for='title'>
							Title:
						</Label>
						<Input
							type='text'
							className='form-control focushide'
							required
							value={title}
							onChange={e => setTitle(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label className='labelText' for='title'>
							Description:
						</Label>
						<Input
							className='jobdesc form-control focushide'
							value={description}
							type='textarea'
							required
							onChange={e => setDescription(e.target.value)}
							placeholder='write your thoughts...'
							rows={3}
						/>
					</FormGroup>
					<FormGroup>
						<Label className='labelText' for='title'>
							Type Of Work:
						</Label>
						<Input
							type='text'
							className='form-control focushide'
							required
							value={type}
							onChange={e => setType(e.target.value)}
						/>
					</FormGroup>
					<Row>
						<Col md={6} xs={12}>
							<FormGroup>
								<Label className='labelText' for='title'>
									Hourly Pay:
								</Label>
								<Input
									type='number'
									className='form-control focushide'
									required
									value={pay}
									onChange={e => setPay(e.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col md={6} xs={12}>
							<FormGroup>
								<Label className='labelText' for='title'>
									Starting From:
								</Label>
								<Input
									type='date'
									className='form-control focushide'
									required
									value={startingDate}
									onChange={e =>
										setStartingDate(e.target.value)
									}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={6} xs={12}>
							<FormGroup>
								<Label className='labelText' for='title'>
									State:
								</Label>
								<Input
									type='text'
									className='form-control focushide'
									required
									value={state}
									onChange={e => setState(e.target.value)}
								/>
							</FormGroup>
						</Col>
						<Col md={6} xs={12}>
							<FormGroup>
								<Label className='labelText' for='title'>
									City:
								</Label>
								<Input
									type='text'
									className='form-control focushide'
									required
									value={city}
									onChange={e => setCity(e.target.value)}
								/>
							</FormGroup>
						</Col>
					</Row>
					<FormGroup>
						<Label className='labelText' for='title'>
							Skills:
						</Label>
						<Autocomplete
							{...defaultSKillsProps}
							autoComplete
							disableClearable={true}
							disableListWrap
							value={skills}
							onChange={(e, val) => {
								setSkills(val);
								// if (skills.length >= 5) {
								// 	toast.warn(
								// 		'Your Limit To Add Skill Filled'
								// 	);
								// } else {
								// 	setSkills(val);
								// }
							}}
							multiple
							id='size-small-filled-multi'
							size='small'
							freeSolo
							renderInput={params => <TextField {...params} />}
						/>
					</FormGroup>

					<div className='d-flex justify-content-end pb-2'>
						<Button
							disabled={
								!title ||
								!description ||
								!state ||
								!city ||
								!pay ||
								!startingDate
							}
							type='submit'
							color='success'
							size='lg'
						>
							{loading ? <Spinner size='sm' /> : 'Submit'}
						</Button>
					</div>
				</Form>
			</div>
		</Container>
	);
};

export default NewJob;
