const initialState = {
	uid: null,
	user: null,
};

/*
    Any action related to Profile will go here.
*/

export default function authUserReducer(state = initialState, action) {
	// console.log(action, 'actions');
	switch (action.type) {
		// case 'USERINFO':
		// 	return {
		// 		...state,
		// 			uid: action.user.id,
		// user: action.user,
		// 	};
		case 'LOGIN_SUCCESS':
			return {
				...state,
				uid: action.user.id,
				user: action.user,
			};
		case 'LOGIN_FAIL':
			localStorage.clear();
			return {
				...state,
			};
		case 'LOGOUT_SUCCESS':
			return {
				...state,
				uid: '',
				user: null,
			};

		default:
			return { ...state };
	}
}
