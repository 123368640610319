import React, { useState } from 'react';
import firebase from '../config/firebase';
import {
	Button,
	Col,
	Container,
	Input,
	InputGroup,
	Label,
	Row,
} from 'reactstrap';
import IrsQualified from '../assets/img/IrsQualified.png';
import ThankYou from '../assets/img/ThankYou.png';
import Paypal from '../components/Paypal';
import { useDispatch } from 'react-redux';
import { donateAmountFunc } from '../store/actions/donateAction';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const DonatePage = () => {
	const dispatch = useDispatch();
	const [amount, setAmount] = useState('');
	const [checkOut, setCheckOut] = useState(false);

	const getPaypalDetail = details => {
		var price = {
			value: amount,
			currency_code: 'USD',
		};
		let obj = {
			donate_id: details.id,
			created_at: firebase.firestore.Timestamp.now(),
			amount: price,
			via: 'paypal',
		};
		// dispatch(updatePaypalEmail(auth.uid, details.payer.email_address));
		dispatch(
			donateAmountFunc({
				obj,
				onSuccess: () => {
					toast.success('Donate Amount Successfully.');
					setAmount('');
					setCheckOut(false);
				},
			})
		);
	};

	return (
		<Container className='donateOuterMain mt-5  px-md-5'>
			{/* <Row className='no-gutters justify-content-center'>
				<Col lg='8'>
					<div className='complaint-card mt-4'>
						<div className='complaint-form'>
							<h5 className='card-title'>
								Do you want to support and give 5$
							</h5>

							<Button
								className='btn btn-block mt-5'
								color='success'
							>
								Pay Now
							</Button>
						</div>
					</div>
				</Col> 
			</Row> */}
			{/* First Row */}
			<Row>
				<Col md='3'>
					<img
						src={IrsQualified}
						alt='IrsQualified'
						className='img-fluid'
					/>
				</Col>
				<Col md='9' className='px-auto'>
					<h4
						className='font-weight-normal align-self-center pt-5'
						// style={{ lineHeight: '1' }}
					>
						<b>
							Career Counseling & Apprenticeship Program for
							Match4Future is supported by UNKLE501, a 501c3
							non-profit.
							<br />
							<br /> Here is the link:{' '}
							<a href='https://unkle501.web.app'>
								https://unkle501.web.app
							</a>
						</b>
					</h4>
				</Col>
			</Row>
			{/* <Row className='justify-content-center px-md-5 mt-4'>
				<h4 className='text-center  font-weight-normal fs-2'>
					Your <u>tax-deductible</u> donation to Underserved Needy
					Kids Learning Entrepreneurship (U.N.K.L.E.) helps support a
					career counseling service and apprenticeship program{' '}
					<u className='font-weight-bold'>
						{' '}
						for the Student Athletes who are NOT GOING PRO in Sports
						(that’s 98% of all Student Athletes!){' '}
					</u>
				</h4>
			</Row> */}
			{/* <Row className='mt-3'>
				<Col md='3'>
					<h3 className='text-center'>Choose donation amount </h3>
				</Col>
				<Col md='5'>
					<div className='d-flex'>
						<div>
							<InputGroup>
								<span className='mt-2'>
									<b>$</b>
								</span>
								<Input
									type='number'
									value={amount}
									onChange={e => setAmount(e.target.value)}
									className='request-form-input ml-2 text-center'
								/>
							</InputGroup>
						</div>
					</div>
				</Col>
				<Col md='4'>
					<h3 className='font-weight-bold fs-2'>
						<Link to='/'>/ / Donate </Link>
					</h3>{' '}
					<br />
				</Col>
			</Row> */}
			{/* <div
				className='d-flex justify-content-center'
				style={{
					width: '300px',
					display: 'block',
					margin: 'auto',
				}}
			>
				{checkOut ? (
					<div className='mt-2 mb-4'>
						<Paypal
							price={amount}
							getPaypalDetail={getPaypalDetail}
						/>
					</div>
				) : (
					<Button
						disabled={!amount}
						className='auth-button-paypal mb-4'
						onClick={e => {
							e.preventDefault();
							setCheckOut(true);
						}}
					>
						Paypal
					</Button>
				)}
			</div> */}
		</Container>
	);
};

export default DonatePage;
