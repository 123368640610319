import firebase from '../../config/firebase';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

export const createNewJob = obj => async dispatch => {
	dispatch(jobLoader(true));
	await firebase
		.firestore()
		.collection('M4F_jobs')
		.add(obj)
		.then(() => {
			dispatch(jobLoader(false));
			toast.success('New Job Created Successfully');
		})
		.catch(err => {
			toast.warning(err.message);
			dispatch(jobLoader(false));
		});
};
export const findJob = obj => async dispatch => {
	// dispatch(jobLoader(true));
	await firebase
		.firestore()
		.collection('M4F_jobs')
		.where('title', '==', obj.title)
		.where('state', '==', obj.state)
		.where('city', '==', obj.city)
		.where('type', '==', obj.type)
		.where('skills', 'array-contains-any', obj.skills)
		.where('status', '==', 'active')
		.get()
		.then(query => {
			let tempArr = [];
			query.docs.forEach(doc => {
				tempArr.push({ id: doc.id, ...doc.data() });
			});
			// dispatch(jobLoader(false));
			let newArr =
				tempArr &&
				tempArr.filter(ele => {
					if (
						ele.hourly_pay >= obj.hourly_pay.min &&
						ele.hourly_pay <= obj.hourly_pay.max
					) {
						return ele;
					}
				});
			if (newArr.length > 0) {
				dispatch({
					type: 'SEARCH_JOBS',
					payload: newArr,
				});
			} else {
				toast.warn('No Job Found');
			}
		})
		.catch(err => {
			toast.warning(err.message);
			// dispatch(jobLoader(false));
		});
};
export const cleanSearchResult = () => async dispatch => {
	dispatch({
		type: 'SEARCH_JOBS',
		payload: [],
	});
};
export const appliedJob = obj => async dispatch => {
	dispatch(jobLoader(true));
	let fileURL;
	// if (obj.file) {
	let customImgKey = uuidv4();
	var imgName = obj.file.name;
	let ext2 = imgName.slice(imgName.lastIndexOf('.'));
	var imgLets = await firebase
		.storage()
		.ref('user_files/' + customImgKey + ext2.toLowerCase())
		.put(obj.file);
	fileURL = await imgLets.ref.getDownloadURL();
	// }
	let data = {
		file_url: {
			file_name: imgName,
			url: fileURL,
		},
		job_id: obj.job_id,
		user_id: obj.user_id,
	};
	await firebase
		.firestore()
		.collection('M4F_applied_jobs')
		.add(data)
		.then(() => {
			dispatch(jobLoader(false));
			toast.success('You Have Applied Successfully');
		})
		.catch(err => {
			toast.warning(err.message);
			dispatch(jobLoader(false));
		});
};

export const fetchAllAppliedJobs = id => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_applied_jobs')
		.where('user_id', '==', id)
		.onSnapshot(async query => {
			var tempMyJobs = [];
			for await (let doc of query.docs) {
				let jobRef = await firebase
					.firestore()
					.collection('M4F_jobs')
					.doc(doc.data().job_id)
					.get();
				tempMyJobs.push({
					jobdata: jobRef.data(),
					id: doc.id,
					...doc.data(),
				});
			}
			dispatch({
				type: 'GET_ALL_APPLIED_JOBS',
				payload: tempMyJobs,
			});
		});
};

export const fetchMembersOfJobs = id => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_applied_jobs')
		.where('job_id', '==', id)
		.onSnapshot(async query => {
			var tempJobMember = [];
			for await (let doc of query.docs) {
				let userRef = await firebase
					.firestore()
					.collection('M4F_users')
					.doc(doc.data().user_id)
					.get();
				tempJobMember.push({
					userData: userRef.data(),
					id: doc.id,
					...doc.data(),
				});
			}
			dispatch({
				type: 'GET_ALL_JOB_MEMBERS',
				payload: tempJobMember,
			});
		});
};

export const fetchAllJobs = () => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_jobs')
		.where('status', '==', 'active')
		.orderBy('created_at', 'desc')
		.limit(10)
		.onSnapshot(query => {
			var tempallJobs = [];
			const lastdata = query.docs[query.docs.length - 1];
			query.docs.forEach(doc => {
				tempallJobs.push({
					id: doc.id,
					...doc.data(),
				});
			});
			dispatch({
				type: 'LAST_DOC',
				payload: lastdata,
			});
			dispatch({
				type: 'GET_ALL_JOBS',
				payload: tempallJobs,
			});
		});
};

export const getMoreJobs = () => async (dispatch, getState) => {
	var tempAllJobs = [];

	let prevJobs = await getState().jobs.allJobs;
	let lastdoc = await getState().jobs.lastDoc;

	if (prevJobs.length > 0) {
		tempAllJobs = prevJobs;
	}

	firebase
		.firestore()
		.collection('M4F_jobs')
		.where('status', '==', 'active')
		.orderBy('created_at', 'desc')
		.startAfter(lastdoc)
		.limit(10)
		.get()
		.then(query => {
			const lastdata = query.docs[query.docs.length - 1];
			query.docs.forEach(doc => {
				tempAllJobs.push({
					id: doc.id,
					...doc.data(),
				});
			});
			dispatch({
				type: 'LAST_DOC',
				payload: lastdata,
			});
			dispatch({
				type: 'GET_ALL_JOBS',
				payload: tempAllJobs,
			});
		});
};

export const fetchMyJobs = id => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_jobs')
		.where('creator_Id', '==', id)
		.onSnapshot(query => {
			var tempMyJobs = [];
			query.forEach(doc => {
				tempMyJobs.push({
					id: doc.id,
					...doc.data(),
				});
			});
			dispatch({
				type: 'GET_JOBS_BY_ID',
				payload: tempMyJobs,
			});
		});
};

export const fetchMyCloseJobs = id => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_jobs')
		.where('creator_Id', '==', id)
		.where('status', '==', 'close')
		.get()
		.then(query => {
			var tempMyJobs = [];
			query.forEach(doc => {
				tempMyJobs.push({
					id: doc.id,
					...doc.data(),
				});
			});
			dispatch({
				type: 'GET_MY_CLOSE_JOBS',
				payload: tempMyJobs,
			});
		});
};

export const updateJob = (id, obj) => async dispatch => {
	firebase
		.firestore()
		.collection('M4F_jobs')
		.doc(id)
		.update({
			title: obj?.title,
			description: obj?.description,
			hourly_pay: obj?.hourly_pay,
			starting_from: obj?.starting_from,
			type: obj?.type,
			state: obj?.state.toUpperCase(),
			city: obj?.city.toUpperCase(),
			skills: obj?.skills,
		})
		.then(() => {
			toast.success('Job Updated Successfully');
		});
};

export const closeJob =
	({ obj, onSuccess }) =>
	async dispatch => {
		firebase
			.firestore()
			.collection('M4F_jobs')
			.doc(obj.id)
			.update({
				status: obj?.status,
				selected_canidate: obj?.selected_canidate,
			})
			.then(() => {
				toast.success('Job Close Successfully');
				onSuccess();
			});
	};

export const jobLoader = val => async dispatch => {
	dispatch({ type: 'SET_JOB_LOADING', payload: val });
};
