import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Spinner,
} from 'reactstrap';
// import art from '../../assets/img/register-person.png';
import art from '../../assets/img/Boys-Picture.jpg';
// import { toast } from 'react-toastify';
import {
	athleteConfirmation,
	userInfo,
	userlogin,
	userRegister,
	userRegisterCollege,
	userRegisterSchool,
} from '../../store/actions/authAction';
import {
	fetchCollegeInstitute,
	fetchCollegeStates,
	fetchSchoolInstitute,
	fetchSchoolStates,
	getCollegeCity,
	getSchoolCounty,
} from '../../store/actions/locationAction';
import { useEffect } from 'react';
const useStyles = makeStyles({
	auto_input: {
		height: '30px',
	},
});

const IndividualRegister = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const history = useHistory();
	let auth = useSelector(state => state.auth);
	const {
		collegeStates,
		schoolStates,
		cities,
		counties,
		collegeInstitutes,
		schoolInstitutes,
	} = useSelector(state => state.location);
	const [firstName, setFirstName] = useState('');
	const [lastName, setSecondName] = useState('');
	const [email, setEmail] = useState('');
	const [number, setNumber] = useState('');
	const [password, setPassword] = useState('');
	const [image, setImage] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [county, setCounty] = useState('');
	const [institute, setInstitute] = useState('');
	const [ischeckedState, setIsCheckedState] = useState('school');
	const [showPassword, setShowPassword] = useState(false);
	const [InternshipCheckBox, setInternshipCheckBox] = useState(false);
	const [registerationFanPlayer, setRegisterationFanPlayer] = useState('');
	const [contactFanPlayer, setContactFanPlayer] = useState('');
	const [guardianContact, setGuardianContact] = useState('');
	const [guardianRelation, setGuardianRelation] = useState('');
	const [agreeParticipatingInternship, setAgreeParticipatingInternship] =
		useState(false);
	const [agreeParticipating, setAgreeParticipating] = useState(false);
	// const [DashboardLoader, setDashboardLoader] = useState(true);
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleStatesChange = e => {
		setIsCheckedState(e);
		setState('');
		setCity('');
		setCounty('');
		setInstitute('');
	};

	const defaultStateProps = {
		options:
			ischeckedState == 'college'
				? collegeStates?.map(insti => {
						return insti.name;
				  })
				: schoolStates?.map(insti => {
						return insti.name;
				  }),
		getOptionLabel: option => option,
	};

	const defaultCityProps = {
		options:
			ischeckedState == 'college'
				? cities?.map(insti => {
						return insti.city;
				  })
				: counties?.map(insti => {
						return insti.county;
				  }),
		getOptionLabel: option => option,
	};

	let newSchools = [];
	const getInstitutes = () => {
		if (ischeckedState == 'college') {
			collegeInstitutes.forEach(insti => {
				insti.institutes?.forEach(ele => {
					newSchools.push(ele);
				});
			});
		} else {
			schoolInstitutes.forEach(insti => {
				insti.institutes?.forEach(ele => {
					newSchools.push(ele);
				});
			});
		}
		if (newSchools) {
			return newSchools;
		}
	};

	const defaultInstituteProps = {
		options: getInstitutes(),
		getOptionLabel: option => option,
	};

	const handleCityOrCounty = value => {
		if (ischeckedState == 'college') {
			setCity(value);
		} else {
			setCounty(value);
		}
	};

	const handleFileChange = e => {
		let MAX_FILE_SIZE = 1024 * 1024;
		const fileSizeKiloBytes = e.target.files[0].size;
		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			toast.warn('File size is greater than 1MB');
		} else {
			setImage(e.target.files[0]);
		}
	};
	const handleFirstNameCheck = val => {
		if (/[^a-z A-Z]/.test(val)) {
			return;
		} else {
			setFirstName(val);
		}
	};
	const handleLastNameCheck = val => {
		if (/[^a-z A-Z]/.test(val)) {
			return;
		} else {
			setSecondName(val);
		}
	};

	const handleChange = value => {
		// console.log(value, 'value');
		const input = value.replace(/\D/g, '').substring(0, 10);
		// Divide numbers in 3 parts :"(123) 456-7890"
		const first = input.substring(0, 3);
		const middle = input.substring(3, 6);
		const last = input.substring(6, 10);

		if (input.length > 6) {
			setGuardianContact(`(${first})-(${middle})-(${last})`);
		} else if (input.length > 3) {
			setGuardianContact(`(${first})-(${middle})`);
		} else if (input.length >= 0) {
			setGuardianContact(input);
		}
		// console.log(guardianContact, 'setGuardianContact');
	};

	useEffect(() => {
		dispatch(fetchCollegeStates());
		dispatch(fetchSchoolStates());
	}, []);

	useEffect(() => {
		if (state != '') {
			if (ischeckedState == 'college') {
				dispatch(getCollegeCity(state));
			} else {
				dispatch(getSchoolCounty(state));
			}
		}
	}, [state]);

	useEffect(() => {
		if (state != '') {
			if (ischeckedState == 'college') {
				if (city != '') {
					dispatch(fetchCollegeInstitute(state, city));
				}
			} else {
				if (county != '') {
					dispatch(fetchSchoolInstitute(state, county));
				}
			}
		}
	}, [state, city, county]);

	// useEffect(() => {
	// 	dispatch(userlogin());
	// });
	const { user, uid } = useSelector(state => state.authUser);
	let { athleteConfirmationLoading } = useSelector(state => state.auth);
	function PushtoDashboard() {
		// alert('Please wait...');
		toast.success('Please wait a few minutes.');
		history.push('/individual-dashboard');
	}

	// console.log(
	// 	athleteConfirmationLoading,
	// 	'athleteConfirmationLoading====>user'
	// );

	// if (user.hasOwnProperty('m4f_guardian_contact')) {
	// 	// history.push('/items-and-condition');

	// }

	return (
		<>
			<Container
				fluid
				className='pt-4 mb-4 pr-md-5 pl-md-5 companyRegisterMain'
			>
				<Card
					className='text-black shadow-lg'
					style={{ borderRadius: '25px' }}
				>
					<CardBody>
						<Row>
							<Col
								md='12'
								lg='6'
								className='d-flex flex-column formWidth'
							>
								{/* <h1 className='text-center fw-bold mx-1 mx-md-4 mt-1'>
									Student Athlete Sign up
								</h1> */}
								<h5>
									I am interested in a short internship. I
									would appreciate help with meals,
									accommodations, and travel. I want to
									mention I have the following attributes/job
									experience/interests:
								</h5>
								{/* <h6 className='text-dark font-weight-bold pb-0 mb-0 w-25'>
									I am Student
									<br />
									Athlete in:
								</h6> */}
								{/* <div className='d-flex justify-content-center mt-2 mb-3'>
									<div className='d-flex allign-items-center mx-3'>
										<Input
											className='radioStyle'
											onChange={() =>
												handleStatesChange('school')
											}
											checked={ischeckedState == 'school'}
											type='radio'
										/>
										<h6 className='ml-1 pt-1'>
											High School
										</h6>
									</div>
									<div className='d-flex allign-items-center mx-3'>
										<Input
											className='radioStyle'
											onChange={() =>
												handleStatesChange('college')
											}
											checked={
												ischeckedState == 'college'
											}
											type='radio'
										/>
										<h6 className='ml-1 pt-1'>College</h6>
									</div>
								</div> */}

								{/* <Form
									onSubmit={e => {
										e.preventDefault();
										let collegeData = {
											firstName: firstName.toLowerCase(),
											lastName: lastName.toLowerCase(),
											email: email,
											no: number,
											img: image,
											role: 'user',
											state: state,
											city: city,
											institute: institute,
											sportLevel: ischeckedState,
										};

										let schoolData = {
											firstName: firstName.toLowerCase(),
											lastName: lastName.toLowerCase(),
											email: email,
											no: number,
											img: image,
											role: 'user',
											state: state,
											county: county,
											institute: institute,
											sportLevel: ischeckedState,
										};

										ischeckedState == 'college'
											? dispatch(
													userRegisterCollege(
														collegeData,
														password,
														() => {
															history.push('/');
														}
													)
											  )
											: dispatch(
													userRegisterSchool(
														schoolData,
														password,
														() => {
															history.push('/');
														}
													)
											  );
									}}
								>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											First Name:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											value={firstName}
											onChange={e =>
												handleFirstNameCheck(
													e.target.value
												)
											}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Last Name:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											value={lastName}
											onChange={e =>
												handleLastNameCheck(
													e.target.value
												)
											}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Email:
										</Label>
										<Input
											required
											type='email'
											className='input-text pl-0'
											value={email}
											onChange={e =>
												setEmail(e.target.value)
											}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Phone No:
										</Label>
										<Input
											required
											onKeyPress={event => {
												if (!/[0-9]/.test(event.key)) {
													event.preventDefault();
												}
											}}
											type='tel'
											className='input-text pl-0'
											value={number}
											onChange={e =>
												handleChange(e.target.value)
											}
											placeholder='(xxx)-(xxx)-(xxxx)'
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-2'>
											Select Image:
										</Label>
										<Input
											type='file'
											name='individual_img'
											onChange={handleFileChange}
											accept='image/png, image/gif, image/jpeg'
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Password:
										</Label>
										<div className='paswordOuter'>
											<Input
												required
												type={
													showPassword
														? 'text'
														: 'password'
												}
												value={password}
												className='input-text pl-0'
												onChange={e =>
													setPassword(e.target.value)
												}
											/>
											{password.length > 0 ? (
												<i
													className='fas fa-eye pointer eyeIcon'
													onClick={handleShowPassword}
												></i>
											) : null}
										</div>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											State:
										</Label>
										<Autocomplete
											{...defaultStateProps}
											id='auto-complete1'
											autoComplete
											includeInputInList
											disableClearable={true}
											value={state}
											disableListWrap
											freeSolo
											onChange={(e, val) => {
												setState(val);
												setCity('');
												setCounty('');
												setInstitute('');
											}}
											renderInput={params => (
												<TextField
													className={
														classes.auto_input
													}
													{...params}
													variant='standard'
												/>
											)}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											{ischeckedState == 'college'
												? 'City'
												: 'County'}
										</Label>
										<Autocomplete
											{...defaultCityProps}
											id='auto-complete2'
											autoComplete
											includeInputInList
											disableClearable={true}
											disableListWrap
											value={
												ischeckedState == 'college'
													? city
													: county
											}
											freeSolo
											onChange={(e, val) =>
												handleCityOrCounty(val)
											}
											renderInput={params => (
												<TextField
													className={
														classes.auto_input
													}
													{...params}
													variant='standard'
												/>
											)}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Institute:
										</Label>
										<Autocomplete
											{...defaultInstituteProps}
											id='auto-complete3'
											autoComplete
											includeInputInList
											value={institute}
											disableClearable={true}
											disableListWrap
											freeSolo
											onChange={(e, val) =>
												setInstitute(val)
											}
											renderInput={params => (
												<TextField
													className={
														classes.auto_input
													}
													{...params}
													variant='standard'
												/>
											)}
										/>
									</div>

									<div className='registerBtn'>
										<Button
											className='btn-success mt-3'
											size='lg'
											disabled={
												!firstName ||
												!lastName ||
												!email ||
												!password
											}
										>
											{auth.loading ? (
												<Spinner size='lg' />
											) : (
												'Register'
											)}
										</Button>
									</div>
								</Form> */}
								<Form
									onSubmit={e => {
										e.preventDefault();
										let obj = {
											m4f_guardian_contact:
												guardianContact,
											m4f_harmless_the_proprietor:
												agreeParticipatingInternship,
											m4f_online_persons_not_trustworthy:
												agreeParticipating,
											m4f_guardianRelation:
												guardianRelation,
										};
										// console.log(obj, 'obj');
										dispatch(
											athleteConfirmation(
												obj,
												user?.id,
												() => {
													dispatch(
														userInfo(user?.id)
													);

													setTimeout(
														PushtoDashboard,
														3000
													);

													// if (
													// 	user.hasOwnProperty(
													// 		'm4f_guardian_contact'
													// 	)
													// ) {
													// 	history.push(
													// 		'/individual-dashboard'
													// 	);
													// } else {
													// 	<Spinner
													// 		size='lg'
													// 		color='dark'
													// 	/>;
													// }
												}
											)
										);
									}}
								>
									{/* <div className='d-flex allign-items-center mx-3 my-2'>
										 <Input
											required
											className='radioStyle'
											value='InternshipCheckBox'
											onChange={e =>
												setInternshipCheckBox(
													e.target.value
												)
											}
											// checked={ischeckedState == 'school'}
											type='radio'
										/>
										<h6 className='ml-1 pt-1 '>
											I would like a mentor, and to be
											part of a team at internship.:
										</h6> 
									</div>*/}
									<div className='form-group form-box mb-1 my-2'>
										<Label className='labelText mb-0'>
											My registration # at FansHelpPlayers
											is:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											value={user?.athleteID}
											// onChange={e =>
											// 	setRegisterationFanPlayer(
											// 		e.target.value
											// 	)
											// }
										/>
									</div>
									<div className='form-group form-box mb-1 my-2'>
										<Label className='labelText mb-0'>
											My Contact info is:
										</Label>
										<Input
											required
											onKeyPress={event => {
												if (!/[0-9]/.test(event.key)) {
													event.preventDefault();
												}
											}}
											type='tel'
											className='input-text pl-0'
											value={user?.phoneNumber}
											// onChange={e =>
											// 	setContactFanPlayer(
											// 		e.target.value
											// 	)
											// }
											placeholder='(xxx)-(xxx)-(xxxx)'
										/>
									</div>
									{/*  */}
									<div className='form-group form-box mb-1 my-2'>
										<Label className='labelText mb-0'>
											My Parent/Advisor Contact info is:
										</Label>
										<Input
											required
											onKeyPress={event => {
												if (!/[0-9]/.test(event.key)) {
													event.preventDefault();
												}
											}}
											type='tel'
											className='input-text pl-0 '
											value={guardianContact}
											onChange={e => {
												// setGuardianContact(
												// 	e.target.value
												// )
												// handleChange(e.target.value)
												let value = e.target.value;
												handleChange(value);
											}}
											placeholder='(xxx)-(xxx)-(xxxx)'
										/>
									</div>
									<div className='form-group form-box mb-1 my-2'>
										<Label className='labelText mb-0'>
											Relationship with guardian
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											// value={email}
											onChange={e =>
												setGuardianRelation(
													e.target.value
												)
											}
										/>
									</div>
									<div className='d-flex allign-items-center mx-3 my-2'>
										<Input
											className='radioStyle'
											value={agreeParticipatingInternship}
											onClick={() =>
												setAgreeParticipatingInternship(
													true
												)
											}
											// checked={ischeckedState == 'school'}
											type='radio'
											required
										/>
										<h6 className='ml-1 pt-1'>
											I agree to hold harmless the
											proprietor of Match4Future regarding
											all matters linked to my use of this
											site.
										</h6>
									</div>

									<div className='d-flex allign-items-center mx-3 my-2'>
										<Input
											required
											className='radioStyle'
											// value='agreeParticipating'
											value='true'
											onClick={() =>
												setAgreeParticipating(true)
											}
											// checked={ischeckedState == 'school'}
											type='radio'
										/>
										<h6 className='ml-1 pt-1'>
											I understand that not all persons
											online are trustworthy. I agree to
											consult with my parents/advisor
											before contacting employer or
											accepting or participating in
											internship via this site.
										</h6>
									</div>

									<div className='registerBtn'>
										<Button
											className='btn-success mt-3'
											size='lg'
											type='submit'
											// !InternshipCheckBox ||
											disabled={
												!agreeParticipatingInternship ||
												!agreeParticipating
											}
										>
											{athleteConfirmationLoading ? (
												<Spinner
													size='lg'
													color='light'
												/>
											) : (
												'continue'
											)}
										</Button>
									</div>
								</Form>
							</Col>

							<Col
								md='12'
								lg='6'
								className='order-1 order-lg-2 d-flex align-items-center justif-content-center'
							>
								<div>
									<img
										src={art}
										className='img-fluid'
										alt='img'
									/>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
};

export default IndividualRegister;
