const initState = {
	schoolLoading: false,
	designaleAlmaData: [],
	designaleAlmaUserData: [],
	stateusers: [],
	stateCity: [],
	countyBasedOnCity: [],
	allSchools: [],

	// allEmployerFromSelectedState: [],
	SchoolFromCities: [],
};

const designateAlmaReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_ALMA_BY_INSTITUTE_COMPANY':
			return {
				...state,
				designaleAlmaData: action.payload,
			};
		case 'GET_ALMA_BY_INSTITUTE_USER':
			return {
				...state,
				designaleAlmaUserData: action.payload,
			};
		case 'GET_STATE_USER':
			return {
				...state,
				stateusers: action.payload,
			};
		case 'GET_CITY_USER':
			return {
				...state,
				stateCity: action.payload,
			};
		case 'GET_COUNTY_BASED_STATE':
			return {
				...state,
				countyBasedOnState: action.payload,
			};
		case 'GET_ALL_SCHOOLS':
			return {
				...state,
				allSchools: action.payload,
			};
		case 'GET_SCHOOLS_BASED_ON_CITY':
			return {
				...state,
				SchoolFromCities: action.payload,
			};
		// case 'HIGH_SCHOOL_INSTITUDE':
		// 	return {
		// 		...state,

		// 	}
		// case 'GET_SELECTED_STATE_EMPLOYER':
		// 	return {
		// 		...state,
		// 		allEmployerFromSelectedState: action.payload,
		// 	};
		case 'Fetch_Counties_School':
			return {
				...state,
				SchoolFromCounties: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};
export default designateAlmaReducer;
