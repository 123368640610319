import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import IndividualPasswordChange from '../components/IndividualPasswordChange';
import UpdateProfile from '../components/UpdateProfile';

// import SideBar from '../components/SideBar';

const IndividualSetting = () => {
	const [collapseOpen, setCollapseOpen] = useState(true);
	const [active, setActive] = useState('1');

	const toggleCollapse = () => {
		setCollapseOpen(!collapseOpen);
	};
	const { user, uid } = useSelector(state => state.authUser);

	const history = useHistory();

	useEffect(() => {
		if (!user.hasOwnProperty('m4f_online_persons_not_trustworthy')) {
			history.push('/terms-and-conditions');
		}
	}, []);

	return (
		<div className='d-flex IndividualSettingMain'>
			<button
				className={
					collapseOpen ? 'collapseBtnTrue' : 'collapseBtnFalse'
				}
				type='button'
				onClick={toggleCollapse}
			>
				<span>
					{collapseOpen ? (
						<i className='fas fa-angle-left'></i>
					) : (
						<i className='fas fa-angle-right'></i>
					)}
				</span>
			</button>
			{collapseOpen ? (
				<div className='sideItems bg-sidebarColor'>
					<button
						className={
							collapseOpen
								? 'collapseBtnTrue'
								: 'collapseBtnFalse'
						}
						type='button'
						onClick={toggleCollapse}
					>
						<span>
							{collapseOpen ? (
								<i className='fas fa-angle-left'></i>
							) : (
								<i className='fas fa-angle-right'></i>
							)}
						</span>
					</button>
					<ul
						style={{
							flexDirection: 'column',
							listStyle: 'none',
							marginTop: '35px',
						}}
					>
						<li className='sideLink'>
							<Link
								className='linkItems'
								to='#'
								onClick={() => setActive('1')}
							>
								<span
									className={
										active == '1' ? 'active' : 'itemText'
									}
								>
									Update Profile
								</span>
							</Link>
						</li>
						<li className='sideLink' onClick={() => setActive('2')}>
							<Link className='linkItems' to='#'>
								<span
									className={
										active == '2' ? 'active' : 'itemText'
									}
								>
									Password
								</span>
							</Link>
						</li>
					</ul>
				</div>
			) : null}

			<div className='settingContents'>
				{active == '1' ? (
					<UpdateProfile />
				) : (
					<IndividualPasswordChange />
				)}
			</div>
		</div>
	);
};

export default IndividualSetting;
