import React from "react";
import { Link } from "react-router-dom";
import match from "../../assets/img/match.jpg";
import four from "../../assets/img/four.png";
import future from "../../assets/img/future.jpg";
import { Button, Col, Container, Row } from "reactstrap";

const Home = () => {
  return (
    <>
      <Container>
        <div className="homeMain">
          <Row className="align-items-center">
            <Col md={4}>
              <img src={match} className="img-fluid" alt="" />
            </Col>
            <Col md={4}>
              <img
                src={four}
                className="img-fluid pt-3 pl-5 pb-3 pr-5"
                alt=""
              />
            </Col>
            <Col md={4}>
              <img src={future} className="img-fluid" alt="" />
            </Col>
            <Col md={12} className="text-center">
              <h2>
                {/* jobs */}
                The service that matches short non-sports internships{" "}
                {/* <span className='text-primary'>view</span>) */}
                to talented current/former student athletes in accordance with
                NCAA guidance.{" "}
                {/* <span className='text-primary'>View</span> */}
              </h2>
            </Col>
            <Col md={12} className="text-center mt-1">
              <Link to="/donate">
                <Button color="success" size="lg" className="home__btn p-4">
                  Donate!
                </Button>
              </Link>
            </Col>
          </Row>

          <Row className=" d-flex justify-content-around mb-2 align-items-center mt-3">
            <Col xs={12} md={4} className="btn-responsive">
              <h4>Employer can</h4>
              <h2>Post Internship</h2>
              <Link to="/company-register">
                <Button color="success" size="lg" className="home__btn p-4">
                  Register here
                </Button>
              </Link>
            </Col>
            <Col xs={12} md={4} className="btn-responsive text-center">
              <h2> &nbsp;</h2>
              <Link to="/login">
                <Button color="danger" size="lg" className="home__btn p-4">
                  LOGIN
                </Button>
              </Link>
            </Col>
            <Col
              xs={12}
              md={4}
              className="btn-responsive individual-btn-responsive"
            >
              <h4>Student Athlete can</h4>
              <h2>Find Internship</h2>
              <Link to="/individual-register">
                <Button color="success" size="lg" className="home__btn p-4">
                  Register here
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Home;
