import firebase from '../../config/firebase';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
// import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

// const history = useHistory();
// let { uid, user } = useSelector(state => state.authUser);

export const setAuthLoader = val => async dispatch => {
	dispatch({ type: 'SET_AUTH_LOADING', payload: val });
};

export const userlogin = credential => async dispatch => {
	// console.log(credential, 'credentials ===> ');
	dispatch(setAuthLoader(true));
	firebase
		.auth()
		.signInWithEmailAndPassword(credential.email, credential.password)
		.then(data => {
			var docRef = firebase
				.firestore()
				.collection('users')
				.doc(data.user.uid);
			docRef
				.get()
				.then(doc => {
					if (doc.data()) {
						// console.log(doc.data(), 'docData(');
						// || doc.data().role == 'employer'
						if (doc.data().role == 'athlete') {
							if (doc.data().blocked) {
								toast.warning(
									'You are not allowed to access this panel'
								);
								dispatch(setAuthLoader(false));
								let user = data?.user.uid;
								dispatch(userInfo(user));
							} else {
								dispatch({
									type: 'LOGIN_SUCCESS',
									user: { id: doc.id, ...doc.data() },
									error: '',
								});
								// toast.success('Account Logged in Successfully');
								dispatch(userInfo(data?.user.uid));
								dispatch(setAuthLoader(false));
							}
						} else {
							toast.warning('You are not allowed to login');
							dispatch(setAuthLoader(false));
						}
					} else {
						toast.warning('You are not allowed to login');
						dispatch(setAuthLoader(false));
					}
				})
				.catch(() => {
					toast.warning('Invalid User');
					dispatch(setAuthLoader(false));
				});
		})
		.catch(() => {
			toast.warning('Invalid User');
			dispatch(setAuthLoader(false));
		});
};
export const userInfo = uid => async dispatch => {
	firebase
		.firestore()
		.collection('users')
		.doc(uid)
		.get()
		.then(async query => {
			dispatch({
				type: 'LOGIN_SUCCESS',
				user: { id: query.id, ...query.data() },
				error: '',
			});
		});
};

export const companyLogin = credential => async dispatch => {
	// console.log(credential, 'credential');

	dispatch(setAuthLoader(true));

	firebase
		.auth()
		.signInWithEmailAndPassword(credential.email, credential.password)
		.then(data => {
			var docRef = firebase
				.firestore()
				.collection('users')
				.doc(data.user.uid);
			docRef.get().then(doc => {
				if (doc.data()) {
					// console.log(doc.data(), 'docData');
					if (doc.data().role == 'employer') {
						if (doc.data().blocked) {
							toast.warning(
								'You are not allowed to access this panel'
							);
							dispatch(setAuthLoader(false));
						} else {
							dispatch({
								type: 'LOGIN_SUCCESS',
								user: { id: doc.id, ...doc.data() },
								error: '',
							});
							// toast.success('Account Logged in Successfully');
							dispatch(setAuthLoader(false));
						}
					}
				} else {
					toast.warning('This User Does Not Exist');
					dispatch(setAuthLoader(false));
				}
			});
		})
		.catch(error => {
			dispatch({
				type: 'LOGIN_FAIL',
				uid: '',
				error: error,
			});
			dispatch(setAuthLoader(false));
			toast.warning(error.message);
		});
};

export const companyRegisterCollege =
	(credential, password, onSuccess = () => {}) =>
	async dispatch => {
		// console.log(credential, 'credential');
		// console.log(password, 'credential');
		dispatch(setAuthLoader(true));
		firebase
			.auth()
			.createUserWithEmailAndPassword(credential.email, password)
			.then(async data => {
				let obj = {
					name: credential.name,
					email: credential.email,
					created_at: firebase.firestore.Timestamp.now(),
					state: credential.state,
					city: credential.city,
					institute: credential.institute,
					sportLevel: credential.sportLevel,
					role: credential.role,
					preferred_Candidate: credential.preferred_Candidate,
					internshipDuration: credential.internshipDuration,
					internship_what: credential.internship_what,
					internship_when: credential.internship_when,
					internship_where: credential.internship_where,
					contact: credential.contact,
					accommodation_assest: credential.accommodation_assest,
					travel_assest: credential.travel_assest,
					meal_assist: credential.meal_assist,
				};
				await firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.set(obj)
					.then(res => {
						dispatch(setAuthLoader(false));
						toast.success('Account Created Successfully');
						onSuccess();
					})
					.catch(error => {
						dispatch({
							type: 'REGISTER_FAIL',
						});
						dispatch(setAuthLoader(false));
						toast.warning(error.message);
					});
			})
			.catch(error => {
				dispatch(setAuthLoader(false));
				toast.warning(error.message);
			});
	};
export const companyRegisterSchool =
	(credential, password, onSuccess = () => {}) =>
	async dispatch => {
		// console.log('credential',credential);
		// console.log('password',password);
		dispatch(setAuthLoader(true));
		firebase
			.auth()
			.createUserWithEmailAndPassword(credential.email, password)
			.then(async data => {
				let obj = {
					name: credential.name,
					email: credential.email,
					created_at: firebase.firestore.Timestamp.now(),
					state: credential.state,
					county: credential.county,
					institute: credential.institute,
					sportLevel: credential.sportLevel,
					role: credential.role,
					preferred_Candidate: credential.preferred_Candidate,
					internshipDuration: credential.internshipDuration,
					internship_what: credential.internship_what,
					internship_when: credential.internship_when,
					internship_where: credential.internship_where,
					contact: credential.contact,
					accommodation_assest: credential.accommodation_assest,
					travel_assest: credential.travel_assest,
					meal_assist: credential.meal_assist,
				};
				await firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.set(obj)
					.then(res => {
						dispatch(setAuthLoader(false));
						toast.success('Account Created Successfully');
						onSuccess();
					})
					.catch(error => {
						dispatch({
							type: 'REGISTER_FAIL',
						});
						dispatch(setAuthLoader(false));
						toast.warning(error.message);
					});
			})
			.catch(error => {
				dispatch(setAuthLoader(false));
				toast.warning(error.message);
			});
	};
export const userRegisterSchool =
	(credential, password, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(setAuthLoader(true));
		firebase
			.auth()
			.createUserWithEmailAndPassword(credential.email, password)
			.then(async data => {
				// 	// Image
				let imgURL;
				if (credential.img) {
					let customImgKey = firebase
						.firestore()
						.collection('users')
						.doc();
					var imgName = credential.img.name;
					let ext2 = imgName.slice(imgName.lastIndexOf('.'));
					var imgLets = await firebase
						.storage()
						.ref(
							'user_files/' + customImgKey.id + ext2.toLowerCase()
						)
						.put(credential.img);
					imgURL = await imgLets.ref.getDownloadURL();
				}
				let obj = {
					first_name: credential.firstName,
					last_name: credential.lastName,
					email: credential.email,
					contact_number: credential.no,
					image_url: credential.img ? imgURL : null,
					role: credential.role,
					state: credential.state,
					county: credential.county,
					institute: credential.institute,
					sportLevel: credential.sportLevel,
					created_at: firebase.firestore.Timestamp.now(),
				};
				await firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.set(obj)
					.then(res => {
						dispatch(setAuthLoader(false));
						toast.success('Account Created Successfully');
						onSuccess();
					})
					.catch(error => {
						dispatch({
							type: 'REGISTER_FAIL',
						});
						dispatch(setAuthLoader(false));
						toast.warning(error.message);
					});
			})
			.catch(error => {
				dispatch(setAuthLoader(false));
				toast.warning(error.message);
			});
	};

export const userRegisterCollege =
	(credential, password, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(setAuthLoader(true));
		firebase
			.auth()
			.createUserWithEmailAndPassword(credential.email, password)
			.then(async data => {
				// 	// Image
				let imgURL;
				if (credential.img) {
					let customImgKey = firebase
						.firestore()
						.collection('users')
						.doc();
					var imgName = credential.img.name;
					let ext2 = imgName.slice(imgName.lastIndexOf('.'));
					var imgLets = await firebase
						.storage()
						.ref(
							'user_files/' + customImgKey.id + ext2.toLowerCase()
						)
						.put(credential.img);
					imgURL = await imgLets.ref.getDownloadURL();
				}
				let obj = {
					first_name: credential.firstName,
					last_name: credential.lastName,
					email: credential.email,
					contact_number: credential.no,
					image_url: credential.img ? imgURL : null,
					state: credential.state,
					city: credential.city,
					institute: credential.institute,
					role: credential.role,
					sportLevel: credential.sportLevel,
					created_at: firebase.firestore.Timestamp.now(),
				};
				await firebase
					.firestore()
					.collection('users')
					.doc(data.user.uid)
					.set(obj)
					.then(res => {
						dispatch(setAuthLoader(false));
						toast.success('Account Created Successfully');
						onSuccess();
					})
					.catch(error => {
						dispatch({
							type: 'REGISTER_FAIL',
						});
						dispatch(setAuthLoader(false));
						toast.warning(error.message);
					});
			})
			.catch(error => {
				dispatch(setAuthLoader(false));
				toast.warning(error.message);
			});
	};

export const athleteAdded = data => async dispatch => {
	await dispatch(athleteAddedLoader(true));
	await firebase
		.firestore()
		.collection('users')
		.add(data)
		.then(() => {
			toast.success('Athlete is  added');
			dispatch(athleteAddedLoader(false));
		})
		.catch(err => {
			toast.success('Data is not added');
			dispatch(athleteAddedLoader(false));
		});
	dispatch(athleteAddedLoader(false));
};
// Loader
export const athleteAddedLoader = val => async dispatch => {
	dispatch({ type: 'ATHLETE_ADDED_LOADER', payload: val });
};

export const athleteConfirmationLoader = val => async dispatch => {
	dispatch({ type: 'SET_CONFIRMATION_LOADING', payload: val });
};
export const athleteConfirmation =
	(data, id, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(athleteConfirmationLoader(true));
		await firebase
			.firestore()
			.collection('users')
			.doc(id)
			.update(data)
			.then(res => {
				dispatch(athleteConfirmationLoader(false));
				// alert('user update successfully');
				toast.success(
					'Athlete is  Confirmed! Please wait a few minutes. The data is being confirmed. You will automatically redirect to the dashboard.'
				);
				dispatch(userInfo(id));
				onSuccess();
				// () => {
				// history.push('/individual-dashboard');
			});
		dispatch(athleteConfirmationLoader(false));
	};
export const logout = () => async dispatch => {
	firebase
		.auth()
		.signOut()
		.then(data => {
			dispatch({
				type: 'LOGOUT_SUCCESS',
				uid: '',
				user: '',
				error: '',
			});
			toast.success('User Logout Successfully');
		})
		.catch(error => {
			dispatch({
				type: 'LOGOUT_FAIL',
				uid: '',
				error: error,
			});
			toast.warn(error.message);
		});
};

export const refreshAuth = uid => async dispatch => {
	firebase
		.firestore()
		.collection('users')
		.doc(uid)
		.onSnapshot(doc => {
			dispatch({
				type: 'LOGIN_SUCCESS',
				user: { id: doc.id, ...doc.data() },
				error: '',
			});
			dispatch({
				type: 'LOGIN_REQUEST_END',
			});
		});
};
export const refreshAuthCompany = uid => async dispatch => {
	firebase
		.firestore()
		.collection('company_users')
		.doc(uid)
		.onSnapshot(doc => {
			dispatch({
				type: 'LOGIN_SUCCESS',
				user: { id: doc.id, ...doc.data() },
				error: '',
			});
			dispatch({
				type: 'LOGIN_REQUEST_END',
			});
		});
};

export const updateInfo = (uid, payload, type) => async dispatch => {
	if (type == 'city') {
		firebase
			.firestore()
			.collection('users')
			.doc(uid)
			.update({ city: payload })
			.then(res => {
				dispatch(refreshAuth(uid));
				toast.success('Profile Updated Successfully');
				return;
			});
	} else if (type == 'state') {
		firebase
			.firestore()
			.collection('users')
			.doc(uid)
			.update({ state: payload })
			.then(res => {
				dispatch(refreshAuth(uid));
				toast.success('Profile Updated Successfully');
				return;
			});
	} else if (type == 'institute') {
		firebase
			.firestore()
			.collection('users')
			.doc(uid)
			.update({ institute: payload })
			.then(res => {
				dispatch(refreshAuth(uid));
				toast.success('Profile Updated Successfully');
				return;
			});
	} else if (type == 'name') {
		firebase
			.firestore()
			.collection('users')
			.doc(uid)
			.update({ first_name: payload })
			.then(res => {
				dispatch(refreshAuth(uid));
				toast.success('Profile Updated Successfully');
				return;
			});
	} else if (type == 'last_name') {
		firebase
			.firestore()
			.collection('users')
			.doc(uid)
			.update({ last_name: payload })
			.then(res => {
				dispatch(refreshAuth(uid));
				toast.success('Profile Updated Successfully');
				return;
			});
	} else if (type == 'county') {
		firebase
			.firestore()
			.collection('users')
			.doc(uid)
			.update({ county: payload })
			.then(res => {
				dispatch(refreshAuth(uid));
				toast.success('Profile Updated Successfully');
				return;
			});
	}
};

export const updateProfilePicture = item => async dispatch => {
	let imgURL;
	if (item.img) {
		if (item.imgURL) {
			await firebase.storage().refFromURL(item.imgURL).delete();
		}
		let customImgKey = uuidv4();

		var imgName = item.img.name;
		let ext2 = imgName.slice(imgName.lastIndexOf('.'));
		var imgLets = await firebase
			.storage()
			.ref('user_files/' + customImgKey + ext2.toLowerCase())
			.put(item.img);
		imgURL = await imgLets.ref.getDownloadURL();
		await firebase
			.firestore()
			.collection('users')
			.doc(item.id)
			.update({
				image_url: item.img ? imgURL : null,
			});
		if (imgURL) {
			firebase
				.firestore()
				.collection('users')
				.doc(item.id)
				.onSnapshot(doc => {
					dispatch({
						type: 'LOGIN_SUCCESS',
						user: { id: doc.id, ...doc.data() },
						error: '',
					});
				});
		}
		toast.success('Update Image Successfully');
	}
};

export const updatePassword = ({ obj, onSuccess }) => {
	return dispatch => {
		dispatch({
			type: 'PASSWORD_REQUEST',
		});
		let user = firebase.auth().currentUser;
		if (user != null) {
			let creds = firebase.auth.EmailAuthProvider.credential(
				user.email,
				obj.currentPassword
			);
			user.reauthenticateWithCredential(creds)
				.then(() => {
					user.updatePassword(obj.newPassword);
					dispatch({
						type: 'PASSWORD_REQUEST_END',
					});
					toast.success('Password Updated Successfully');
					onSuccess();
				})
				.catch(error => {
					toast.warning('Current Password is Invalid');
					dispatch({
						type: 'PASSWORD_REQUEST_END',
					});
				});
		} else {
			dispatch({
				type: 'PASSWORD_REQUEST_END',
			});
		}
	};
};

export const updateCompanyInfo = (uid, payload, type) => async dispatch => {
	if (type == 'companyName') {
		firebase
			.firestore()
			.collection('company_users')
			.doc(uid)
			.update({ name: payload })
			.then(res => {
				dispatch(refreshAuthCompany(uid));
				toast.success('Company Name Updated Successfully');
				return;
			});
	}
};
