import React from 'react';
import { Col, Container, Row } from 'reactstrap';

function prospectiveEmployer() {
	return (
		<Container>
			<Row>
				<Col>
					<div>
						<h4 className='align-self-center'>
							Details about short internships at Match4Future
						</h4>

						<ol>
							<li>
								The M4F site allows Student Athletes to get into
								contact with prospective employers. Then the{' '}
								<u>
									student athlete and employer can work out
									terms between themselves.
								</u>
							</li>
							<li>
								The initial internship is typically 2 or 3 days
								in length, as a convenience to employer and
								student athlete.
								<u>
									{' '}
									Long term employment might later be arranged
									if student athlete and employer agree.
								</u>
							</li>
							<li>
								The employer and the student athlete work out
								the terms of payment for internship, as well as
								the travel, accommodations, and meals
								arrangements.{' '}
							</li>
							<li>
								The employer is advised that most student
								athletes are low on money, and the employer will
								be doing the student athlete a{' '}
								<u>
									favor if employer can arrange at least some
									help with travel, accommodations, and meals.
								</u>
								Student athlete’s work during internship will be
								payment to employer for these arrangements.
							</li>
							<li>
								The employer and the student athlete will be
								requested to{' '}
								<u>each supply a rating of the internship </u>{' '}
								experience. This assists M4F to continually
								improve the site
							</li>
						</ol>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default prospectiveEmployer;
