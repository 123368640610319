import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../store/actions/authAction';
import Image from '../assets/img/match.jpg';

const IndividualNavBar = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<div className='navbarCompMain'>
			<nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
				<Link className='navbar-brand p-0' to='/'>
					<img src={Image} height='40px' width='100%' />
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					data-toggle='collapse'
					data-target='#navbarTogglerDemo02'
					aria-controls='navbarTogglerDemo02'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>

				<div
					className='collapse navbar-collapse'
					id='navbarTogglerDemo02'
				>
					<ul className=' ml-auto navbar-nav mt-2 mt-lg-0 align-items-center'>
						<li className='nav-item'>
							<Link
								id={
									history.location.pathname ==
									'/individual-dashboard'
										? 'active1'
										: ''
								}
								className='nav-link active1'
								to='/individual-dashboard'
							>
								Dashboard
							</Link>
						</li>
						<li className='nav-item'>
							<Link
								className='nav-link active1'
								to='/designate-alma-matter-user'
							>
								Designate Alma Matter
							</Link>
						</li>
						<li className='nav-item'>
							<Link
								className='nav-link active1'
								to='/applied-jobs'
							>
								Applied Jobs
							</Link>
						</li>

						<li className='nav-item'>
							<Link
								className='nav-link active1'
								to='/individual-chat'
							>
								Chat
							</Link>
						</li>

						<li className='nav-item'>
							<Link
								className='nav-link active1'
								to='/individual-setting'
							>
								Setting
							</Link>
						</li>

						<li className='nav-item'>
							<div className='dropdown'>
								<Link
									to='#'
									className='nav-link dropdown-toggle'
									id='dropdownMenuButton'
									data-toggle='dropdown'
								>
									<i className='fas fa-user-circle fa-2x iconClr'></i>
								</Link>
								<div
									className='dropdown-menu dropdown-menu-right'
									aria-labelledby='dropdownMenuButton'
								>
									<Link
										className='dropdown-item'
										to='/login'
										onClick={() => dispatch(logout())}
									>
										Logout
									</Link>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
};

export default IndividualNavBar;
