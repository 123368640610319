import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Col,
	Collapse,
	Container,
	Form,
	Input,
	Label,
	Row,
} from 'reactstrap';
import { updateCompanyInfo } from '../store/actions/authAction';

const CompanyProfile = () => {
	const dispatch = useDispatch();
	let { user, uid } = useSelector(state => state.authUser);
	const [companyName, setCompanyName] = useState('');

	const [fields, setFields] = useState({
		companyNameEdit: false,
	});

	const fieldToggle = name => {
		let newField = JSON.parse(JSON.stringify(fields));
		for (let field of Object.keys(newField)) {
			if (field != name) newField[field] = false;
		}
		newField[name] = !newField[name];
		setFields(newField);
	};

	return (
		<Container fluid className='changePswrdMain mt-4 mt-md-0'>
			<Row className='no-gutters justify-content-center'>
				<Col lg='8'>
					<div className='complaint-card mt-4'>
						<div className='complaint-form'>
							<h5 className='card-title'>Company Profile </h5>
							<Row>
								<Col sm={12} md={6}>
									<p className='edit-password-info'>
										Company Name:
									</p>
								</Col>
								<Col sm={12} md={6}>
									<div
										className={`${
											!fields.companyNameEdit
												? 'd-flex'
												: 'd-none'
										}`}
									>
										<p className='edit-password-info'>
											{user.name}
										</p>
										<i
											className='fa fa-pencil-alt mt-2 ml-2 pointer'
											onClick={() =>
												fieldToggle('companyNameEdit')
											}
										></i>
									</div>
									<Form
										className={`${
											fields.companyNameEdit
												? 'd-flex'
												: 'd-none'
										}`}
										onSubmit={e => {
											e.preventDefault();
											dispatch(
												updateCompanyInfo(
													uid,
													companyName,
													'companyName'
												)
											).then(res => {
												fieldToggle('companyNameEdit');
												setCompanyName('');
											});
										}}
									>
										<Input
											required
											className='custom-input mt-0'
											type='text'
											placeholder='Enter Company Name'
											onChange={e =>
												setCompanyName(e.target.value)
											}
										/>
										<Button
											className='mr-1 ml-3'
											color='primary'
											type='submit'
										>
											<i className='fas fa-check'></i>
										</Button>
										<Button
											className=''
											color='danger'
											onClick={() =>
												fieldToggle('companyNameEdit')
											}
										>
											<i className='fas fa-times'></i>
										</Button>
									</Form>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default CompanyProfile;
