const initialState = {
	chats: [],
	messagesById: [],
	unReadMessages: [],
	loading: false,
	unSub: null,
};

const companyChatReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_ALL_CHAT_BY_ID':
			return {
				...state,
				chats: action.payload,
			};
		case 'GET_ALL_INDIVIDUAL_CHAT_BY_ID':
			return {
				...state,
				chats: action.payload,
			};
		case 'CHAT_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'GET_ALL_MESSAGES_BY_ID':
			return {
				...state,
				messagesById: action.payload,
			};
		case 'GET_ALL_UNREAD_MESSAGE':
			return {
				...state,
				unReadMessages: action.payload,
			};
		case 'SET_UNSUB':
			return {
				...state,
				unSub: action.payload,
			};

		default:
			return {
				...state,
			};
	}
};
export default companyChatReducer;
