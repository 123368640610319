import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../assets/img/bgImg.jpg";
import firebase from "../config/firebase";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import {
  createChat,
  createMessage,
  getAllIndividualChatsById,
} from "../store/actions/companyChatAction";
import {
  fetchAllEmployer,
  fetchAllSchools,
  fetchCitiesFromState,
  fetchCityUSER,
  fetchCountiesFromState,
  fetchCountiesSchool,
  fetchCountyBasedOnState,
  fetchDesignateAlmaMatterUSER,
  fetchSchoolCities,
  fetchStateUSER,
} from "../store/actions/designateAlmaAction";
import { Link, useHistory } from "react-router-dom";
import zIndex from "@mui/material/styles/zIndex";
import {
  getCollegeCity,
  getSchoolCounty,
  fetchCollegeInstitute,
  fetchSchoolInstitute,
} from "../store/actions/locationAction";

const UserDesignateAlmaMatter = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [search, setSearch] = useState("");
  const [chatModal, setChatModal] = useState(false);
  const [selctDropDown, setSelctDropDown] = useState("");
  const [stateDropDown, setStateDropDown] = useState("");
  const [cityDropDown, setCityDropDown] = useState("");
  const [countyDropDown, setCountyDropDown] = useState("");
  const [schoolDropDown, setSchoolDropDown] = useState("");
  const [selectState, setSelectState] = useState("");
  const [stateUsers_dropDown, setStateUsers_dropDown] = useState("");
  const [checkbox_Travel, setCheckbox_Travel] = useState(false);
  const [checkbox_Accomodation, setCheckBox_Accomodation] = useState(false);
  const [checkBox_meal, setCheckBox_Meal] = useState(false);
  const [citiesSchoolDropDown, setCitiesSchoolDropDown] = useState("");
  const [schoolCountyDropDown, setSchoolCountyDropDown] = useState("");
  const [filter, setFilter] = useState({
    baseFilter: "",
    state: "",
    baseState: "",
    city: "",
    county: "",
    school: "",
  });

  const [tempCity, setTempCity] = useState("");
  const [tempCounty, setTempCounty] = useState("");

  const { user, uid } = useSelector((state) => state.authUser);
  const { cities, counties, collegeInstitutes, schoolInstitutes } = useSelector(
    (state) => state.location
  );

  const {
    designaleAlmaUserData,
    stateusers,
    stateCity,
    countyBasedOnState,
    allSchools,
    SchoolFromCities,
    SchoolFromCounties,
  } = useSelector((state) => state.designate);

  const { chats, loading } = useSelector((state) => state.companyChat);

  // const handleChanges = (e) => {
  //   setFilter((ev) => ({
  //     ...ev,
  //     [e.target.name]: e.target.value,
  //     school: "",
  //     city: "",
  //     county: "",
  //     baseState: "",
  //   }));
  // };
  const handleChange = (e) => {
    setFilter((ev) => ({
      ...ev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChatModal = () => {
    setChatModal(!chatModal);
  };
  const handleMessageBtn = (id) => {
    setChatModal(true);
    setReceiverId(id);
  };
  let filterFunction = () => {
    return (
      designaleAlmaUserData &&
      designaleAlmaUserData
        .filter((data) => {
          if (
            (filter.baseFilter === "" ||
              filter.baseFilter === "All" ||
              filter.baseFilter === "state") &&
            filter.state === ""
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            data?.state.toUpperCase() === filter.state.toUpperCase() &&
            filter.city === "" &&
            filter.county === ""
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "city" &&
            filter.city !== "" &&
            filter.school === "" &&
            data?.city?.toUpperCase() === filter?.city?.toUpperCase()
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "county" &&
            filter.county !== "" &&
            filter.school === "" &&
            data?.county?.toUpperCase() === filter?.county?.toUpperCase()
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "city" &&
            filter.city !== "" &&
            filter.school !== "" &&
            filter.baseState !== "All" &&
            data?.institute?.toUpperCase() === filter?.school?.toUpperCase()
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "county" &&
            filter.county !== "" &&
            filter.school !== "" &&
            data?.institute?.toUpperCase() === filter?.school?.toUpperCase()
          ) {
            return data;
          }
        })

        .filter((data) => {
          if (
            checkBox_meal === true &&
            checkbox_Accomodation === true &&
            checkbox_Travel === true &&
            data?.meal_assist === checkBox_meal &&
            data?.accommodation_assest === checkbox_Accomodation &&
            data?.travel_assest === checkbox_Travel
          ) {
            return data;
          } else if (
            checkBox_meal === true &&
            checkbox_Accomodation === true &&
            checkbox_Travel === false &&
            data?.meal_assist === checkBox_meal &&
            data?.accommodation_assest === checkbox_Accomodation
          ) {
            return data;
          } else if (
            checkBox_meal === true &&
            checkbox_Travel === true &&
            checkbox_Accomodation === false &&
            data?.meal_assist === checkBox_meal &&
            data?.travel_assest === checkbox_Travel
          ) {
            return data;
          } else if (
            checkbox_Accomodation === true &&
            checkbox_Travel === true &&
            checkBox_meal === false &&
            data?.travel_assest === checkbox_Travel &&
            data?.accommodation_assest === checkbox_Accomodation
          ) {
            return data;
          } else if (
            checkbox_Accomodation === true &&
            checkBox_meal === false &&
            checkbox_Travel === false &&
            data?.accommodation_assest === checkbox_Accomodation
          ) {
            return data;
          } else if (
            checkbox_Travel === true &&
            checkBox_meal === false &&
            checkbox_Accomodation === false &&
            data?.travel_assest === checkbox_Travel
          ) {
            return data;
          } else if (
            checkBox_meal === true &&
            checkbox_Accomodation === false &&
            checkbox_Travel === false &&
            data?.meal_assist === checkBox_meal
          ) {
            return data;
          } else if (
            checkBox_meal === false &&
            checkbox_Accomodation === false &&
            checkbox_Travel === false
          ) {
            return data;
          }
        })
        .filter((data) =>
          data?.name?.toLowerCase()?.includes(search?.toLowerCase())
        )
    );
  };

  useEffect(() => {
    dispatch(fetchDesignateAlmaMatterUSER(user.institute));
  }, [user]);
  useEffect(() => {
    dispatch(getAllIndividualChatsById(uid));
  }, []);

  const history = useHistory();

  useEffect(() => {
    if (!user.hasOwnProperty("m4f_online_persons_not_trustworthy")) {
      history.push("/terms-and-conditions");
    }
  }, []);
  useEffect(() => {
    // if (selectState) {
    dispatch(fetchStateUSER());
    // }
  }, []);
  useEffect(() => {
    dispatch(getCollegeCity(filter.state));
    dispatch(getSchoolCounty(filter.state));
  }, [filter.state]);
  useEffect(() => {
    dispatch(fetchCollegeInstitute(filter.state, filter.city));
  }, [filter.state, filter.city]);
  useEffect(() => {
    dispatch(fetchSchoolInstitute(filter.state, filter.county));
  }, [filter.state, filter.county]);
  //
  useEffect(() => {
    dispatch(fetchSchoolCities(cityDropDown));
  }, [cityDropDown]);
  useEffect(() => {
    dispatch(fetchCountiesSchool(countyDropDown));
  }, [countyDropDown]);

  useEffect(() => {
    if (
      cityDropDown !== "" ||
      (cityDropDown !== "Select" && SchoolFromCities?.length > 0)
    ) {
      let tempArr = SchoolFromCities?.find((data) => data.city);
      console.log(tempArr, "TempArr of useEEffect");
      setTempCity(tempArr);
    }
  }, [cityDropDown]);

  useEffect(() => {
    if (
      countyDropDown !== "" ||
      (countyDropDown !== "Select" && SchoolFromCounties?.length > 0)
    ) {
      let tempCounty = SchoolFromCounties?.find((data) => data.city);
      setTempCounty(tempCounty);
    }
  }, [countyDropDown]);
  console.log("county", countyBasedOnState);
  console.log("statecity", stateCity);
  return (
    <Container fluid className="designateAlmaMain">
      <h3 className="text-center mb-4 pt-2">
        <span style={{ borderBottom: "3px dashed black" }}>
          Designate Alma Matter
        </span>
      </h3>
      <Row className="mt-5 d-flex justify-content-between">
        <Col xs="12" md="4" className="align-self-center">
          <label>Search By Name</label>
          <Input
            className="form-control"
            placeholder="Search by Company name"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        {/* //DropDown */}
        <Col xs="12" md="8" className="align-self-center">
          <Row>
            <Col xs="12" md="4" lg="2">
              <label for="baseFilter">Select</label>
              <Input
                type="select"
                name="baseFilter"
                value={filter.baseFilter}
                onChange={(e) => {
                  handleChange(e);
                  setFilter((ev) => ({
                    ...ev,
                    [e.target.name]: e.target.value,
                    state: "",
                    school: "",
                    city: "",
                    county: "",
                    baseState: "",
                  }));
                }}
              >
                <option value="Select">Select</option>
                <option value="All">All</option>
                <option value="state">State</option>
              </Input>
            </Col>
            {filter.baseFilter === "state" ? (
              <Col xs="12" md="4" lg="2">
                <label for="state">Select State</label>
                <Input
                  type="select"
                  name="state"
                  value={filter.state}
                  onChange={(e) => {
                    handleChange(e);
                    setFilter((ev) => ({
                      ...ev,
                      [e.target.name]: e.target.value,

                      school: "",
                      city: "",
                      county: "",
                      baseState: "",
                    }));
                  }}
                >
                  <option value="Select">Select</option>
                  {stateusers.length > 0 &&
                    stateusers?.map((state, index) => {
                      return (
                        <>
                          <option key={index} value={state.name}>
                            {state?.name}
                          </option>
                        </>
                      );
                    })}
                </Input>
              </Col>
            ) : (
              ""
            )}
            {filter.state.length > 0 && filter.state != "Select" ? (
              <Col xs="12" md="4" lg="2">
                <label for="state">Select Area</label>
                <Input
                  type="select"
                  name="baseState"
                  value={filter.baseState}
                  onChange={(e) => {
                    handleChange(e);
                    setFilter((ev) => ({
                      ...ev,
                      [e.target.name]: e.target.value,

                      school: "",
                      city: "",
                      county: "",
                    }));
                  }}
                >
                  <option value="Select">Select</option>
                  <option value="All">All</option>
                  {cities.length > 0 ? <option value="city">City</option> : ""}
                  {counties.length > 0 ? (
                    <option value="county">County</option>
                  ) : (
                    ""
                  )}
                </Input>
              </Col>
            ) : (
              ""
            )}
            {filter.baseState === "city" ? (
              <Col xs="12" md="4" lg="2">
                <label for="city">Select City </label>
                <Input
                  type="select"
                  name="city"
                  value={filter.city}
                  onChange={(e) => {
                    handleChange(e);
                    setFilter((ev) => ({
                      ...ev,
                      [e.target.name]: e.target.value,

                      school: "",

                      county: "",
                    }));
                  }}
                >
                  <option value="Select">Select</option>
                  {cities.map((data, idx) => {
                    return (
                      <option key={idx} value={data.city} className="d-col">
                        {data.city}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            ) : (
              ""
            )}
            {filter.baseState === "county" ? (
              <Col xs="12" md="4" lg="2">
                <label for="county">Select County </label>
                <Input
                  type="select"
                  name="county"
                  value={filter.county}
                  onChange={(e) => {
                    handleChange(e);
                    setFilter((ev) => ({
                      ...ev,
                      [e.target.name]: e.target.value,

                      school: "",
                      city: "",
                    }));
                  }}
                >
                  <option value="Select">Select </option>
                  {counties?.map((element, ind) => {
                    return (
                      <option key={ind} value={element.county}>
                        {element.county}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            ) : (
              ""
            )}
            {(filter.city != "" || filter.county != "") &&
            filter.baseState != "All" ? (
              <Col xs="12" md="4" lg="2">
                <label for="school">Select School </label>
                <Input
                  type="select"
                  name="school"
                  value={filter.school}
                  onChange={handleChange}
                >
                  <option value="Select">Select </option>
                  {filter.city != "" && filter.county === ""
                    ? collegeInstitutes?.map((stat, index) => {
                        return stat?.institutes.map((data, idx) => {
                          return (
                            <option key={idx} value={data}>
                              {data}
                            </option>
                          );
                        });
                      })
                    : // (institutes?.map((element, ind) => {
                      //   return (
                      //     <option key={ind} value={element.school}>
                      //       {element.school}
                      //     </option>
                      //   );
                      // }))
                      schoolInstitutes?.map((stat, index) => {
                        return stat?.institutes.map((data, idx) => {
                          return (
                            <option key={idx} value={data}>
                              {data}
                            </option>
                          );
                        });
                      })}
                </Input>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Col>
        <Col>
          <div style={{ marginTop: "1rem" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: "3rem",
                }}
              >
                <Label for="meal">Meal</Label>
                <Input
                  id="meal"
                  type="checkbox"
                  className="ml-3 mt-2"
                  checked={checkBox_meal}
                  onChange={(e) => setCheckBox_Meal(e.target.checked)}
                />
              </div>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  marginRight: "3rem",
                }}
              >
                <Label for="Accomodation">Accommodation</Label>
                <Input
                  id="Accomodation"
                  type="checkbox"
                  className="ml-3 mt-2"
                  checked={checkbox_Accomodation}
                  onChange={(e) => setCheckBox_Accomodation(e.target.checked)}
                />
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <Label for="Travel">Travel</Label>
                <Input
                  id="Travel"
                  type="checkbox"
                  className="ml-3 mt-2"
                  checked={checkbox_Travel}
                  onChange={(e) => setCheckbox_Travel(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {/* 	// console.log(data, 'Data of States');
							// console.log(cityDropDown, 'cityDropDown');
							// console.log(stateCity, 'countyDropDown');

							// search == '' || data.name
							// ?.toLowerCase()
							// .includes(search?.toLowerCase()) ||
							// if (selctDropDown.includes('All')) {
							// 	return data;
							// } else if (
							// 	data.name
							// 		?.toLowerCase()
							// 		.includes(search?.toLowerCase()) ||
							// 	selectState.includes(data?.state) == true ||
							// 	(cityDropDown.includes(data?.city) == true &&
							// 		countyDropDown.includes(data?.county) ==
							// 			true &&
							// 		data?.travel_assest &&
							// 		checkbox_Travel == true) ||
							// 	(data?.accommodation_assest &&
							// 		checkbox_Accomodation == true) ||
							// 	(data?.meal_assist && checkBox_meal == true)
							// ) {
							// 	return data;
							// }
							//  else if (search == '') {
							// 	return data;
							// } else if (
							// 	data.name
							// 		?.toLowerCase()
							// 		.includes(search?.toLowerCase())
							// ) {
							// 	return data; */}
        {filterFunction()?.length > 0 ? (
          filterFunction().map((ele, index) => {
            // console.log(ele, 'ement');
            return (
              <Col xs={12} md="6" xl="4">
                <Card className="mb-4 mt-3 mb-xl-0" key={index}>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={4}>
                        <div className="text-center">
                          <img
                            src={ele.image_url ? ele.image_url : defaultImg}
                            className="img-fluid avatar"
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="text-center">
                          <h6>
                            <b>{ele?.name}</b>
                          </h6>
                          <h6>
                            {ele.sportLevel == "school" ? (
                              <b>
                                ({ele.state},{ele.county})
                              </b>
                            ) : (
                              <b>
                                ({ele.state},{ele.city})
                              </b>
                            )}
                          </h6>
                          <h6>
                            <b>{ele.institute}</b>
                          </h6>
                          <a href={`mailto:${ele.email}`}>
                            <h6>
                              <b>{ele.email}</b>
                            </h6>
                          </a>
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        color="success"
                        className="mr-2"
                        onClick={() => handleMessageBtn(ele.id)}
                      >
                        Message
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })
        ) : (
          <Col>
            <h2 className="text-center mt-3">
              You Have No Designate Alma Matter to show
            </h2>
          </Col>
        )}
        {/* State */}
        {/* {selctDropDown?.includes('ALL') ||
				stateDropDown?.includes('ALL')
					? designaleAlmaUserData.length > 0 &&
					  designaleAlmaUserData
							.filter(element => element.state)
							.map((state, index) => {
								// console.log(state, 'state');
								return (
									<>
										
										<Col xs={12} md='6' xl='4'>
											<Card
												className='mb-4 mt-3 mb-xl-0'
												key={index}
											>
												<CardBody>
													<Row className='align-items-center'>
														<div className='text-center'>
															<h6>
																<b>
																	{
																		state?.state
																	}
																</b>
															</h6>
														</div>
													</Row>
												</CardBody>
											</Card>
										</Col> 
										
										
									</>
								);
							})
					: ''}
				{cityDropDown.includes('ALL')
					? designaleAlmaUserData.length > 0 &&
					  designaleAlmaUserData
							.filter(element => element.city)
							.map((state, index) => {
								// console.log(state, 'state');
								return (
									<>
										<Col xs={12} md='6' xl='4'>
											<Card
												className='mb-4 mt-3 mb-xl-0'
												key={index}
											>
												<CardBody>
													<Row className='align-items-center'>
														<div className='text-center'>
															<h6>
																<b>
																	{
																		state?.city
																	}
																</b>
															</h6>
														</div>
													</Row>
												</CardBody>
											</Card>
										</Col>
									</>
								);
							})
					: ''}  */}
      </Row>

      {/* Modal For Send Message */}
      <Modal isOpen={chatModal} toggle={handleChatModal} centered>
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i onClick={handleChatModal} className="fas fa-times"></i>
            </div>
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              let checkFilter =
                chats &&
                chats.filter((chat) => {
                  if (
                    chat.participants &&
                    chat.participants.find((par) => par == receiverId) &&
                    chat.participants.find((par) => par == uid)
                  ) {
                    return chat;
                  }
                });

              let chatObj = {
                created_at: firebase.firestore.Timestamp.now(),
                participants: [receiverId, uid],
              };
              let msgObj = {
                message: message,
                sender_id: uid,
                read: false,
              };
              // let chatNotificationObj = {
              // 	created_at: firebase.firestore.Timestamp.now(),
              // 	creator_id: uid,
              // 	notification:
              // 		user?.name + ' has sent you a new message',
              // 	type: 'chat_new_message',
              // 	read_by: [],
              // 	receiver_id: props.ele.id,
              // 	redirect_id: checkFilter[0].id,
              // };

              if (checkFilter.length > 0) {
                dispatch(createMessage(checkFilter[0].id, msgObj));
                setMessage("");
              } else {
                dispatch(createChat(chatObj, msgObj));
                setMessage("");
              }
              handleChatModal();
            }}
          >
            <div>
              <div className="">
                <Label className="labelText mt-3">Start a conversation:</Label>
              </div>
              <Input
                className="mt-2 ChatTextArea"
                type="textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="write your Message..."
                rows={5}
              />
            </div>

            <div className="d-flex mt-4 justify-content-end">
              <Button
                disabled={!message}
                className="bg-success border-0 mr-1 text-white"
                type="submit"
              >
                {loading ? <Spinner size="sm" /> : "Send"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default UserDesignateAlmaMatter;
