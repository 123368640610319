import firebase from "../../config/firebase";
// import { toast } from 'react-toastify';

export const fetchDesignateAlmaMatterUSER = (value) => async (dispatch) => {
  firebase
    .firestore()
    .collection("users")
    // .collection('company_users')
    // .where('institute', '==', value)
    .where("role", "==", "employer")
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_ALMA_BY_INSTITUTE_USER",
        payload: tempDesignateAlma,
      });
    });
};
export const fetchStateUSER = (state) => async (dispatch) => {
  firebase
    .firestore()
    .collection("states")
    // .collection('company_users')
    // .where('institute', '==', value)
    // .where('state', '==', state)
    // .where('role', '==', 'employer')
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_STATE_USER",
        payload: tempDesignateAlma,
      });
    });
};
export const fetchCityUSER = (states) => async (dispatch) => {
  firebase
    .firestore()
    .collection("institution")
    // .collection('company_users')
    // .where('institute', '==', value)
    .where("state", "==", states)
    // .where('role', '==', 'employer')
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_CITY_USER",
        payload: tempDesignateAlma,
      });
    });
};
// high_school_institution
export const fetchCountyBasedOnState = (states) => async (dispatch) => {
  // console.log(states, 'states, in fetchCountyBasedOnState');
  firebase
    .firestore()
    .collection("high_school_institution")
    // .collection('company_users')
    // .where('institute', '==', value)
    .where("state", "==", states)
    // .where('role', '==', 'employer')
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_COUNTY_BASED_STATE",
        payload: tempDesignateAlma,
      });
    });
};
export const fetchAllEmployer = (state) => async (dispatch) => {
  firebase
    .firestore()
    .collection("states")
    // .collection('company_users')
    // .where('institute', '==', value)
    .where("name", "==", state)
    // .where('role', '==', 'employer')
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_SELECTED_STATE_EMPLOYER",
        payload: tempDesignateAlma,
      });
    });
};
// school_states
export const fetchAllSchools = (state) => async (dispatch) => {
  firebase
    .firestore()
    .collection("test_schools")
    // .collection('company_users')
    // .where('institute', '==', value)
    .where("state", "==", state)
    // .where('role', '==', 'employer')
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_ALL_SCHOOLS",
        payload: tempDesignateAlma,
      });
    });
};
// City
export const fetchSchoolCities = (states) => async (dispatch) => {
  // console.log(states, '====>SchoolCitiesActions');
  // // .collection('company_users')
  // .where('institute', '==', value)
  // .where('role', '==', 'employer')
  firebase
    .firestore()
    .collection("schools")
    .where("city", "==", states)
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_SCHOOLS_BASED_ON_CITY",
        payload: tempDesignateAlma,
      });
    });
};
// fetchCountiesSchool
export const fetchCountiesSchool = (states) => async (dispatch) => {
  firebase
    .firestore()
    .collection("schools")
    .where("city", "==", states)
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "Fetch_Counties_School",
        payload: tempDesignateAlma,
      });
    });
};
export const fetchDesignateAlmaMatterCompany = (value) => async (dispatch) => {
  firebase
    .firestore()
    // .collection('M4F_users')
    .collection("users")
    //.where('institute', '==', value)
    .where("role", "==", "athlete")
    .onSnapshot((query) => {
      var tempDesignateAlma = [];
      query.forEach((doc) => {
        tempDesignateAlma.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch({
        type: "GET_ALMA_BY_INSTITUTE_COMPANY",
        payload: tempDesignateAlma,
      });
    });
};
