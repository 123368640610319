import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

const TermsAndConditionsAcceptancePage = () => {
	const history = useHistory();
	// const [flag, setFlag] = useState(false);
	let { uid, user } = useSelector(state => state.authUser);
	return (
		<div>
			<Container>
				<h4 className='mt-2 font-weight-bold'>
					The Match4Future program adheres to NCAA guidance, such as
					that below, EXCERPTED from publication: ‘The NCAA's New NIL
					Guidance: How the NCAA Has Loosened Restrictions on
					Collectives
				</h4>
				<h5>
					(excerpted/Modified from an Original article WRITTEN BY
					william m. lawrence of law firm burr and forman, llp)
				</h5>
				<div className='text-center '>
					{/* <a href='https://www.jdsupra.com/legalnews/the-ncaa-s-new-nil-guidance-how-the-3717587/'>
						https://www.jdsupra.com/legalnews/the-ncaa-s-new-nil-guidance-how-the-3717587/
					</a> */}
					<a
						href='https://www.jdsupra.com/legalnews/the-ncaa-s-new-nil-guidance-how-the-3717587/'
						target='_blank'
						style={{ color: 'blue' }}
					>
						https://www.jdsupra.com/legalnews/the-ncaa-s-new-nil-guidance-how-the-3717587/
					</a>
				</div>
				<p className='my-2'>
					‘The three key criteria to permissible Institutional
					promotional activities include whether the promotional
					benefits (i) are available to all students and not strictly
					student athletes, (ii) have a direct or indirect financial
					value to student athletes or financial cost to Institutions,
					and (iii) are made available on commercially arm's length
					bases.’
				</p>
				<p className='my-2'>
					‘Accordingly, as long as student athletes are "off duty"
					from an Institutional standpoint at the time, they can, from
					an NCAA standpoint, generally devote however much time they
					want to NIL activities, and, subject to the New Guidance's
					limitations, Institutions can assist and support those
					activities.’
				</p>
				<p>
					Thus, for participation in Match4Future, the employer/host
					offering internship via Match4Future, and the student
					athlete accepting and participating in such internship, each
					understand and agree that:
				</p>
				<ul>
					<li>
						a. During M4F internship, the student athlete is
						expected to work, i.e., to perform such duties as a
						trainee in the non-sports internship could perform.
					</li>
					<li>
						b. The M4F internee student athlete is allowed to
						receive compensation for such work, at the prevailing
						rate as what could be paid to non-student athletes in
						similar trainee programs.
					</li>
					<li>
						c. The compensation can comprise meals, and/or
						accommodation, and/or stipend for travel, so long as the
						total compensation received by the student athlete is
						within reasonable range of what a non-student-athlete
						could receive for similar trainee position/work.
					</li>
				</ul>

				<div className='my-4'>
					{/* <Link to={`/athlete-confirmation`}> */}
					{user.hasOwnProperty('m4f_guardian_contact') === true ? (
						<Button
							color='primary'
							className='float-right'
							onClick={() =>
								history.push('/individual-dashboard')
							}
						>
							Agree
						</Button>
					) : (
						<Button
							color='primary'
							className='float-right'
							onClick={() =>
								history.push('/athlete-confirmation')
							}
						>
							Agree
						</Button>
					)}
					{/* </Link> */}
				</div>
			</Container>
		</div>
	);
};

export default TermsAndConditionsAcceptancePage;
