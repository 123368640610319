import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../config/firebase';
import { Button, Form, Input } from 'reactstrap';
import RecieveChat from './RecieveChat';
import SendChat from './SendChat';
import { createChat, createMessage } from '../store/actions/companyChatAction';

const ChatViewBody = props => {
	let dispatch = useDispatch();
	const imgRef = useRef(null);

	const { messagesById, chats } = useSelector(state => state.companyChat);
	const { user, uid } = useSelector(state => state.authUser);
	const [message, setMessage] = useState('');
	const [messageFile, setMessageFile] = useState('');

	return (
		<div className='chatViewpage'>
			<div className='msgInner'>
				<div className='msgMain'>
					{messagesById &&
						messagesById.map((ele, index) => {
							return ele.sender_id == uid ? (
								<SendChat
									key={index}
									msgText={ele.message}
									created_at={ele.created_at}
									messageFile_url={ele.messageFile_url}
								/>
							) : (
								<RecieveChat
									key={index}
									msgText={ele.message}
									created_at={ele.created_at}
									messageFile_url={ele.messageFile_url}
								/>
							);
						})}
				</div>
			</div>
			<Form>
				<div>
					<Input
						className='mt-3 adminTextArea'
						type='textarea'
						value={message}
						onChange={e => setMessage(e.target.value)}
						placeholder='write your thoughts...'
						rows={3}
					/>
				</div>
				<p className='text-left pt-2'>{messageFile?.name}</p>
				<div className='d-flex mt-3 ml-2'>
					<i
						className='fas fa-link IconCircle'
						onClick={() => {
							imgRef.current.click();
						}}
					>
						<input
							ref={imgRef}
							type='file'
							hidden
							onChange={e => setMessageFile(e.target.files[0])}
						/>
					</i>
					{message || messageFile ? (
						<i
							className='fas fa-paper-plane ml-2 IconCircle'
							onClick={e => {
								e.preventDefault();
								let checkFilter =
									chats &&
									chats.filter(chat => {
										if (
											chat.participants &&
											chat.participants.find(
												par => par == props.receiverId
											) &&
											chat.participants.find(
												par => par == uid
											)
										) {
											return chat;
										}
									});

								let chatObj = {
									created_at:
										firebase.firestore.Timestamp.now(),
									participants: [props.receiverId, uid],
								};
								let msgObj = {
									message: message,
									sender_id: uid,
									messageFile: messageFile,
									read: false,
								};

								if (checkFilter.length > 0) {
									dispatch(
										createMessage(checkFilter[0].id, msgObj)
									);
									setMessage('');
									setMessageFile('');
								} else {
									dispatch(createChat(chatObj, msgObj));
									setMessage('');
									setMessageFile('');
								}
							}}
						></i>
					) : (
						<i className='fas fa-paper-plane ml-2 IconCircle'></i>
					)}
				</div>
			</Form>
		</div>
	);
};

export default ChatViewBody;
