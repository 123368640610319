// import firebase from "firebase/app";
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// const firebaseConfig = {
// 	apiKey: 'AIzaSyDfdwtruRgG3yEChMVPuOhUfKY4ZfULeIU',
// 	authDomain: 'match4future-platform.firebaseapp.com',
// 	projectId: 'match4future-platform',
// 	storageBucket: 'match4future-platform.appspot.com',
// 	messagingSenderId: '652072660328',
// 	appId: '1:652072660328:web:695dfcad91634da83bfadd',
// 	measurementId: 'G-F9G9352YC4',
// };

const firebaseConfig = {
	apiKey: 'AIzaSyAhFvijjf-QgePs3vb9pfxD0n2DAwlLGSc',
	authDomain: 'fans-help-players.firebaseapp.com',
	projectId: 'fans-help-players',
	storageBucket: 'fans-help-players.appspot.com',
	messagingSenderId: '669033615466',
	appId: '1:669033615466:web:7c82c65c7bd5c962fc7a13',
	measurementId: 'G-0H4XT7VTGD',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
