const initState = {
	loading: false,
	myJobs: [],
	allAppliedJobs: [],
	searchJobsResult: [],
	jobMembers: [],
	closeJobs: [],
	allJobs: [],
	lastDoc: [],
};

const jobReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_JOBS_BY_ID':
			return {
				...state,
				myJobs: action.payload,
			};
		case 'GET_ALL_JOBS':
			return {
				...state,
				allJobs: action.payload,
			};
		case 'GET_MY_CLOSE_JOBS':
			return {
				...state,
				closeJobs: action.payload,
			};
		case 'SEARCH_JOBS':
			return {
				...state,
				searchJobsResult: action.payload,
			};
		case 'LAST_DOC':
			return {
				...state,
				lastDoc: action.payload,
			};
		case 'GET_ALL_APPLIED_JOBS':
			return {
				...state,
				allAppliedJobs: action.payload,
			};
		case 'GET_ALL_JOB_MEMBERS':
			return {
				...state,
				jobMembers: action.payload,
			};
		case 'SET_JOB_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};
export default jobReducer;
