import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CompanyProfile from './CompanyProfile';
import PasswordChange from './PasswordChange';

// import SideBar from '../components/SideBar';

const Setting = () => {
	const [collapseOpen, setCollapseOpen] = useState(true);
	const [active, setActive] = useState('1');

	const toggleCollapse = () => {
		setCollapseOpen(!collapseOpen);
	};

	const { user, uid } = useSelector(state => state.authUser);

	const history = useHistory();

	// useEffect(() => {
	// 	if (user.hasOwnProperty('m4f_guardian_contact') === false) {
	// 		history.push('/terms-and-conditions');
	// 	}
	// }, []);

	return (
		<div className='d-flex SettingMain'>
			<button
				className={
					collapseOpen ? 'collapseBtnTrue' : 'collapseBtnFalse'
				}
				type='button'
				onClick={toggleCollapse}
			>
				<span>
					{collapseOpen ? (
						/* <i className='fas fa-times'></i> */
						<i className='fas fa-angle-left'></i>
					) : (
						/* <i className='fas fa-bars'></i> */
						<i className='fas fa-angle-right'></i>
					)}
				</span>
			</button>
			{/* <Collapse navbar isOpen={collapseOpen}> */}
			{collapseOpen ? (
				<div className='sideItems bg-sidebarColor'>
					<button
						className={
							collapseOpen
								? 'collapseBtnTrue'
								: 'collapseBtnFalse'
						}
						type='button'
						onClick={toggleCollapse}
					>
						<span>
							{collapseOpen ? (
								/* <i className='fas fa-times'></i> */
								<i className='fas fa-angle-left'></i>
							) : (
								/* <i className='fas fa-bars'></i> */
								<i className='fas fa-angle-right'></i>
							)}
						</span>
					</button>
					<ul
						style={{
							flexDirection: 'column',
							listStyle: 'none',
							marginTop: '35px',
						}}
					>
						<li className='sideLink'>
							<Link
								className='linkItems'
								to='#'
								onClick={() => setActive('1')}
							>
								<span
									className={
										active == '1' ? 'active' : 'itemText'
									}
								>
									Company Profile
								</span>
							</Link>
						</li>
						<li className='sideLink' onClick={() => setActive('2')}>
							<Link className='linkItems' to='#'>
								<span
									className={
										active == '2' ? 'active' : 'itemText'
									}
								>
									Password
								</span>
							</Link>
						</li>
					</ul>
				</div>
			) : null}

			{/* </Collapse> */}

			<div className='settingContents'>
				{active == '1' ? <CompanyProfile /> : <PasswordChange />}
			</div>
		</div>
	);
};

export default Setting;
