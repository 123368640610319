import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import bgImg from '../assets/img/bgImg.jpg';
import { closeJob, fetchMembersOfJobs } from '../store/actions/jobAction';

const JobActivity = () => {
	const { jobID } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const [canidates, setCanidates] = useState([]);
	const [searchMember, setSearchMember] = useState('');

	const { jobMembers } = useSelector(state => state.jobs);

	const handleSelectedCanidates = e => {
		let newArray = [...canidates, e.target.value];
		if (canidates.includes(e.target.value)) {
			newArray = newArray.filter(item => item !== e.target.value);
		}
		setCanidates(newArray);
	};

	useEffect(() => {
		dispatch(fetchMembersOfJobs(jobID));
	}, []);

	return (
		<>
			<Container fluid className='jobActivityMain'>
				<Row className='mt-4'>
					<Col xs='7' md='9'>
						<div className='ml-3'>
							<Input
								className='form-control searchField'
								placeholder='search by first name or last name'
								type='text'
								onChange={e => setSearchMember(e.target.value)}
							/>
						</div>
					</Col>
					<Col xs='5' md='3'>
						<Button
							disabled={canidates.length == 0}
							color='success'
							style={{ float: 'right' }}
							onClick={() => {
								let obj = {
									status: 'close',
									selected_canidate: canidates,
									id: jobID,
								};
								dispatch(
									closeJob({
										obj,
										onSuccess: () => {
											setCanidates([]);
											history.push('/my-jobs');
										},
									})
								);
							}}
						>
							Close Job
						</Button>
					</Col>
				</Row>
				<Container fluid className='mt-3'>
					<Row>
						{jobMembers && jobMembers.length > 0 ? (
							jobMembers
								.filter(member => {
									if (searchMember == '') {
										return member;
									} else if (
										member.userData.first_name
											?.toLowerCase()
											.includes(
												searchMember.toLowerCase()
											) ||
										member.userData.last_name
											?.toLowerCase()
											.includes(
												searchMember.toLowerCase()
											)
									) {
										return member;
									}
								})
								.map(ele => {
									return (
										<Col lg='6' xl='4'>
											<Card className='mb-2 mt-3 mb-xl-0'>
												<CardBody>
													<div className='d-flex align-items-center'>
														<div>
															<img
																src={
																	ele.userData
																		.image_url
																		? ele
																				.userData
																				?.image_url
																		: bgImg
																}
																style={{
																	height: '60px',
																	width: '60px',
																	borderRadius:
																		'50%',
																}}
															/>
														</div>
														<div className='ml-4'>
															<span>
																<b>
																	{
																		ele
																			.userData
																			?.first_name
																	}
																</b>
															</span>
															<br />
															<span>
																<b>
																	{
																		ele
																			.userData
																			?.last_name
																	}
																</b>
															</span>
														</div>
													</div>
													<div className='mt-3 px-5'>
														<a
															href={
																ele.file_url
																	?.url
															}
															target='_blank'
														>
															<span className='mb-1'>
																<b>
																	View Resume
																</b>
															</span>
														</a>
														<br />
														<FormGroup className='m-0 mt-2'>
															<Label>
																<b>
																	Select
																	Canidate
																</b>
															</Label>
															<Input
																style={{
																	height: '15px',
																	width: '15px',
																}}
																value={
																	ele.user_id
																}
																type='checkbox'
																onChange={
																	handleSelectedCanidates
																}
																className='ml-1 mt-1'
															/>
														</FormGroup>
													</div>
												</CardBody>
											</Card>
										</Col>
									);
								})
						) : (
							<Col>
								<h2 className='text-center mt-3'>
									You Have No Member Yet
								</h2>
							</Col>
						)}
					</Row>
				</Container>
			</Container>
		</>
	);
};

export default JobActivity;
