import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import firebase from '../config/firebase';
import {
	Button,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import MultiRangeSlider from './MultiRangeSlider';
import { findJob } from '../store/actions/jobAction';

const SearchJobs = () => {
	const dispatch = useDispatch();
	const [title, setTitle] = useState('');
	const [type, setType] = useState('');
	const [pay, setPay] = useState();
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [skills, setSkills] = useState([]);
	

	const defaultSKillsProps = {
		options: skills.map(insti => {
			return insti;
		}),
		getOptionLabel: option => option,
	};
	return (
		<div className='searchJobCard'>
			<h3 className='text-center mb-2 pt-2'>
				<span
					style={{
						borderBottom: '3px dashed black',
					}}
				>
					Search Job
				</span>
			</h3>

			<Form
				onSubmit={e => {
					e.preventDefault();
					let obj = {
						title: title,
						hourly_pay: pay,
						type: type,
						state: state.toUpperCase(),
						city: city.toUpperCase(),
						skills: skills,
					};
					dispatch(findJob(obj));
					// setTitle('');
					// setPay('');
					// setType('');
					// setState('');
					// setCity('');
					// setSkills([]);
				}}
			>
				<FormGroup>
					<Label className='labelText' for='title'>
						Title:
					</Label>
					<Input
						type='text'
						className='form-control focushide'
						required
						value={title}
						onChange={e => setTitle(e.target.value)}
					/>
				</FormGroup>

				<Row>
					<Col md={6} xs={12}>
						<FormGroup>
							<Label className='labelText' for='title'>
								Hourly Pay:
							</Label>
							<MultiRangeSlider
								min={0}
								max={150}
								onChange={min => {
									setPay(min);
								}}
							/>
						</FormGroup>
					</Col>
					<Col md={6} xs={12} className='mt-2 mt-md-0'>
						<FormGroup>
							<Label className='labelText' for='title'>
								Type Of Work:
							</Label>
							<Input
								type='text'
								className='form-control focushide'
								required
								value={type}
								onChange={e => setType(e.target.value)}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={12} xs={12}>
						<FormGroup>
							<Label className='labelText' for='title'>
								Skills:
							</Label>
							<Autocomplete
								{...defaultSKillsProps}
								autoComplete
								disableClearable={true}
								disableListWrap
								value={skills}
								onChange={(e, val) => {
									setSkills(val);
									// if (skills.length >= 5) {
									// 	toast.warn(
									// 		'Your Limit To Add Skill Filled'
									// 	);
									// } else {
									// 	setSkills(val);
									// }
								}}
								multiple
								id='size-small-filled-multi'
								size='small'
								freeSolo
								renderInput={params => (
									<TextField {...params} />
								)}
							/>
						</FormGroup>
					</Col>
					<Col md={6} xs={12}></Col>
				</Row>
				<Row>
					<Col md={6} xs={12}>
						<FormGroup>
							<Label className='labelText' for='title'>
								State:
							</Label>
							<Input
								type='text'
								className='form-control focushide'
								required
								value={state}
								onChange={e => setState(e.target.value)}
							/>
						</FormGroup>
					</Col>
					<Col md={6} xs={12}>
						<FormGroup>
							<Label className='labelText' for='title'>
								City:
							</Label>
							<Input
								type='text'
								className='form-control focushide'
								required
								value={city}
								onChange={e => setCity(e.target.value)}
							/>
						</FormGroup>
					</Col>
				</Row>

				<div className='d-flex justify-content-end pb-2'>
					<Button
						disabled={
							!title ||
							!state ||
							!city ||
							!pay ||
							!skills ||
							!type
						}
						type='submit'
						color='success'
						size='lg'
					>
						Search
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default SearchJobs;
