import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import MyJobsCard from './MyJobsCard';

const AppliedJobs = ({ ele }) => {
	const [showData, setShowData] = useState({});
	const [showJobData, setShowJobData] = useState(false);

	const showDataFunc = data => {
		setShowData(data);
		setShowJobData(true);
	};

	const handleJobData = () => {
		setShowJobData(!showJobData);
	};
	return (
		<>
			<div className='p-2 jobCardMain'>
				<h4>{ele?.jobdata.title}</h4>
				<p
					className='mb-2'
					style={{
						fontWeight: '500',
					}}
				>
					{ele?.jobdata.description}
				</p>

				<div className='d-flex justify-content-between align-items-center'>
					<span
						className={
							ele.jobdata?.status == 'active'
								? 'badge p-2 badge-success'
								: 'badge p-2 badge-danger'
						}
					>
						{ele.jobdata.status}
					</span>

					<Button
						className='mb-1'
						color='success'
						onClick={() => showDataFunc(ele.jobdata)}
					>
						View
					</Button>
				</div>
			</div>

			{/* Modal For View Job Data */}

			<Modal isOpen={showJobData} toggle={handleJobData} centered>
				<ModalBody>
					<div className='d-flex justify-content-end'>
						<div>
							<i
								onClick={handleJobData}
								className='fas fa-times'
							></i>
						</div>
					</div>
					<MyJobsCard ele={showData} />
				</ModalBody>
			</Modal>
		</>
	);
};

export default AppliedJobs;
