import AuthView from '../views/auth/AuthView';
import MainView from '../views/MainView';
import Dashboad from '../views/Dashboard';
import Home from '../views/auth/Home';
import IndividualRegister from '../views/auth/IndividualRegister';
import CompanyRegister from '../views/auth/CompanyRegister';
import Login from '../views/auth/Login';
import CompanyDashboard from '../views/CompanyDashboard';
import NewJob from '../views/NewJob';
import MyJobs from '../views/MyJobs';
import JobActivity from '../views/JobActivity';
import Chat from '../views/Chat';
import Setting from '../views/Setting';
import IndividualDashboard from '../views/IndividualDashboard';
import DesignateAlmaMatter from '../views/DesignateAlmaMatter';
import UserDesignateAlmaMatter from '../views/UserDesignateAlmaMatter';
import AllAppliedJobs from '../views/AllAppliedJobs';
import UserChat from '../views/UserChat';
import IndividualSetting from '../views/IndividualSetting';
import SearchJobResult from '../views/SearchJobResult';
import DonatePage from '../views/DonatePage';
import NewHome from '../views/NewHome.js';
import TermsAndConditons from '../views/TermsAndConditionsAcceptancePage';
import ProspectiveEmployerInternship from '../views/ProspectiveEmployerInternship';

let routes = [
	{
		path: '/auth',
		component: AuthView,
		layout: 'auth',
	},
	{
		path: '/login',
		component: Login,
		layout: 'auth',
	},
	// {
	// 	path: '/individual-register',
	// 	component: IndividualRegister,
	// 	layout: 'auth',
	// },
	{
		path: '/athlete-confirmation',
		component: IndividualRegister,
		layout: 'main',
	},
	// {
	// 	path: '/company-register',
	// 	component: CompanyRegister,
	// 	layout: 'auth',
	// },
	{
		path: '/company-register',
		component: CompanyRegister,
		layout: 'auth',
	},
	{
		path: '/donate',
		component: DonatePage,
		layout: 'auth',
	},
	{
		path: '/main-view',
		component: MainView,
		layout: 'main',
	},
	{
		path: '/dashboard',
		component: Dashboad,
		layout: 'main',
	},
	{
		path: '/companyDashboard',
		component: CompanyDashboard,
		layout: 'companyMain',
	},

	{
		path: '/new-job',
		component: NewJob,
		layout: 'companyMain',
	},
	{
		path: '/my-jobs',
		component: MyJobs,
		layout: 'companyMain',
	},
	{
		path: '/job-activity/:jobID',
		component: JobActivity,
		layout: 'companyMain',
	},
	{
		path: '/designate-alma-matter',
		component: DesignateAlmaMatter,
		layout: 'companyMain',
	},
	{
		path: '/chat',
		component: Chat,
		layout: 'companyMain',
	},
	{
		path: '/setting',
		component: Setting,
		layout: 'companyMain',
	},
	{
		path: '/individual-dashboard',
		component: IndividualDashboard,
		layout: 'main',
	},
	{
		path: '/designate-alma-matter-user',
		component: UserDesignateAlmaMatter,
		layout: 'main',
	},
	{
		path: '/terms-and-conditions',
		component: TermsAndConditons,
		layout: 'main',
	},
	{
		path: '/ProspectiveEmployerInternship',
		component: ProspectiveEmployerInternship,
		layout: 'main',
	},
	{
		path: '/applied-jobs',
		component: AllAppliedJobs,
		layout: 'main',
	},
	{
		path: '/individual-chat',
		component: UserChat,
		layout: 'main',
	},
	{
		path: '/individual-setting',
		component: IndividualSetting,
		layout: 'main',
	},
	{
		path: '/job-search-result',
		component: SearchJobResult,
		layout: 'main',
	},
	{
		path: '/',
		component: NewHome,
		layout: 'auth',
	},

	{
		path: '/home',
		component: Home,
		layout: 'auth',
	},
];
export default routes;
