const initState = {
	schoolLoading: false,
	schoolStates: [],
	collegeStates: [],
	cities: [],
	counties: [],
	schools: [],
	schoolsById: [],
	schoolInstitutes: [],
	schoolInstitutesId: [],
	collegeInstitutes: [],
	collegeInstitutesId: [],
};

const locationReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_COLLEGE_STATES':
			return {
				...state,
				collegeStates: action.payload,
			};
		case 'GET_SCHOOL_STATES':
			return {
				...state,
				schoolStates: action.payload,
			};
		case 'GET_CITY_BY_STATE':
			return {
				...state,
				cities: action.payload,
			};
		case 'GET_COUNTY_BY_STATE':
			return {
				...state,
				counties: action.payload,
			};
		case 'NEW_SCHOOLS':
			return {
				...state,
				schoolInstitutes: action.payload,
			};
		case 'NEW_COLLEGE_UNIVERSITIES':
			return {
				...state,
				collegeInstitutes: action.payload,
			};
		case 'SET_JOB_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};
export default locationReducer;
