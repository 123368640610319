const initState = {
	loading: false,
	passwordLoading: false,
	authError: '',
	athleteConfirmationLoading: false,
	athleteAddedLoading: false,
};
const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'REGISTER_FAIL':
			return {
				...state,
			};
		case 'REGISTER_COMPLETE':
			return {
				...state,
			};

		case 'SET_AUTH_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'PASSWORD_REQUEST':
			return {
				...state,
				passwordLoading: true,
			};
		case 'PASSWORD_REQUEST_END':
			return {
				...state,
				passwordLoading: false,
			};
		case 'SET_CONFIRMATION_LOADING':
			return {
				...state,
				athleteConfirmationLoading: action.payload,
			};
		case 'ATHLETE_ADDED_LOADER':
			return {
				...state,
				athleteAddedLoading: action.payload,
			};
		default:
			return {
				...state,
			};
	}
};

export default authReducer;
