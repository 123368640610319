import React, { useState } from 'react';
import ChatViewBody from '../components/ChatViewBody';
import ChatViewHeader from '../components/ChatViewHeader';
import defaultImg from '../assets/img/bgImg.jpg';
import { Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
	getAllIndividualChatsById,
	getMessageById,
} from '../store/actions/companyChatAction';
import { useHistory } from 'react-router-dom';

const UserChat = () => {
	const dispatch = useDispatch();
	const [isChatOpn, setIsChatOpen] = useState(false);
	const [receiverId, setReceiverId] = useState('');
	const [receiverName, setReceiverName] = useState('');
	const [searchMsg, setSearchMsg] = useState('');

	const { chats, loading, unSub } = useSelector(state => state.companyChat);

	const { user, uid } = useSelector(state => state.authUser);

	const handleMessages = async (Id, UidData, data) => {
		let filterUid = UidData.filter(ele => ele != uid);
		setReceiverId(filterUid?.[0]);
		setReceiverName(data);
		if (unSub != null) {
			await unSub();
		}
		dispatch(getMessageById(Id));

		setIsChatOpen(true);
	};

	const history = useHistory();
	useEffect(() => {
		dispatch(getAllIndividualChatsById(uid));
	}, []);
	useEffect(() => {
		if (!user.hasOwnProperty('m4f_online_persons_not_trustworthy')) {
			history.push('/terms-and-conditions');
		}
	}, []);
	return (
		<Container fluid>
			<Row>
				<Col align='center' className='mt-4 mb-2'>
					<div className='ChatLeft'>
						<div className='header d-flex justify-content-between'>
							<p>
								<strong>Messages</strong>
							</p>
							<i className='fas fa-ellipsis-v'></i>
						</div>
						<div className='chat'>
							<Row className='m-0 p-0'>
								<Col lg={3} md={3} sm={12} className=' p-0'>
									<div className='Middle rightBorder'>
										<div className='inputOuter'>
											<input
												placeholder='Search Messages'
												onChange={e =>
													setSearchMsg(e.target.value)
												}
											/>
										</div>
									</div>
									<div className='profileOuter'>
										{chats?.length > 0 &&
											chats
												.filter(chat => {
													if (searchMsg == '') {
														return chat;
													} else if (
														chat.chatUser.name
															?.toLowerCase()
															.includes(
																searchMsg.toLowerCase()
															)
													) {
														return chat;
													}
												})
												.map((ele, index) => {
													return (
														<div
															className='profile mt-2'
															key={index}
														>
															<div
																className='d-flex pointer'
																onClick={() =>
																	handleMessages(
																		ele.id,
																		ele.participants,
																		ele.chatUser
																	)
																}
															>
																<img
																	src={
																		ele
																			.chatUser
																			?.image_url
																			? ele
																					.chatUser
																					.image_url
																			: defaultImg
																	}
																/>
																<h5 className='pt-2 pl-3 text-left'>
																	{ele
																		.chatUser
																		?.name
																		? ele
																				.chatUser
																				.name
																		: 'N/A'}
																</h5>
															</div>
														</div>
													);
												})}
									</div>
								</Col>
								<Col lg={9} md={9} sm={12} className='p-0'>
									<div className='Middle'>
										<div className='outerHeader'>
											{isChatOpn ? (
												<ChatViewHeader
													text='individual'
													// receiverIdParam={receiverIdParam}
													// receiverId={receiverId}
													receiverName={receiverName}
												/>
											) : (
												''
											)}
										</div>
									</div>
									{isChatOpn ? (
										<ChatViewBody
											receiverId={receiverId}
											// receiverIdParam={receiverIdParam}
										/>
									) : chats.length > 0 ? (
										''
									) : (
										<p>No Conversation Yet</p>
									)}
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default UserChat;
