import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';

import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Spinner,
} from 'reactstrap';
// import art from '../../assets/img/people-art.jpg';
import art from '../../assets/img/uncle.png';

import {
	athleteAdded,
	companyRegisterCollege,
	companyRegisterSchool,
} from '../../store/actions/authAction';
import {
	fetchCollegeInstitute,
	fetchCollegeStates,
	fetchSchoolInstitute,
	fetchSchoolStates,
	getCollegeCity,
	getSchoolCounty,
} from '../../store/actions/locationAction';
import { useEffect } from 'react';

const useStyles = makeStyles({
	auto_input: {
		height: '30px',
	},
});

const CompanyRegister = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const history = useHistory();
	const { auth, athleteAddedLoading, loading } = useSelector(
		state => state.auth
	);
	const {
		collegeStates,
		schoolStates,
		cities,
		counties,
		collegeInstitutes,
		schoolInstitutes,
	} = useSelector(state => state.location);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [county, setCounty] = useState('');
	const [institute, setInstitute] = useState('');
	const [ischeckedState, setIsCheckedState] = useState('school');
	const [showPassword, setShowPassword] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const [internshipDuration, setInternshipDuration] = useState('');
	const [internshipDurationWhen, setInternshipDurationWhen] = useState('');
	const [internshipDurationWhere, setInternshipDurationWhere] = useState('');
	const [internshipDurationWhat, setInternshipDurationWhat] = useState('');
	const [providerTravelAssist, setProviderTravelAssist] = useState(false);
	const [accommodationsAssist, setAccommodationAssist] = useState(false);
	const [mealAssist, setMealAssist] = useState(false);
	const [preferredCandidate, setPreferredCandidate] = useState('');
	const [contact, setContact] = useState('');

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleStatesChange = e => {
		setIsCheckedState(e);
		setState('');
		setCity('');
		setCounty('');
		setInstitute('');
	};
	const handleNameCheck = val => {
		// console.log(val, 'val');
		// if (/[^a-z A-Z]/.test(val)) {

		setName(val);
		console.log(name);

		// }
	};

	const defaultStateProps = {
		options:
			ischeckedState == 'college'
				? collegeStates?.map(insti => {
						return insti.name;
				  })
				: schoolStates?.map(insti => {
						return insti.name;
				  }),
		getOptionLabel: option => option,
	};

	const defaultCityProps = {
		options:
			ischeckedState == 'college'
				? cities?.map(insti => {
						return insti.city;
				  })
				: counties?.map(insti => {
						return insti.county;
				  }),
		getOptionLabel: option => option,
	};

	let newSchools = [];
	const getInstitutes = () => {
		if (ischeckedState == 'college') {
			collegeInstitutes.forEach(insti => {
				insti.institutes?.forEach(ele => {
					newSchools.push(ele);
				});
			});
		} else {
			schoolInstitutes.forEach(insti => {
				insti.institutes?.forEach(ele => {
					newSchools.push(ele);
				});
			});
		}
		if (newSchools) {
			return newSchools;
		}
	};

	const defaultInstituteProps = {
		options: getInstitutes(),
		getOptionLabel: option => option,
	};

	const handleCityOrCounty = value => {
		if (ischeckedState == 'college') {
			setCity(value);
			// console.log(city, 'city');
		} else {
			setCounty(value);
			// console.log(county, 'county');
		}
	};

	useEffect(() => {
		dispatch(fetchCollegeStates());
		dispatch(fetchSchoolStates());
	}, []);

	useEffect(() => {
		if (state != '') {
			if (ischeckedState == 'college') {
				dispatch(getCollegeCity(state));
			} else {
				dispatch(getSchoolCounty(state));
			}
		}
	}, [state]);

	useEffect(() => {
		if (state != '') {
			if (ischeckedState == 'college') {
				if (city != '') {
					dispatch(fetchCollegeInstitute(state, city));
				}
			} else {
				if (county != '') {
					dispatch(fetchSchoolInstitute(state, county));
				}
			}
		}
	}, [state, city, county]);

	return (
		<>
			<Container
				fluid
				className='pt-4 pr-md-5 pl-md-5 companyRegisterMain'
			>
				<Card
					className='text-black shadow-lg'
					style={{ borderRadius: '25px' }}
				>
					<CardBody>
						<Row>
							<Col
								lg='6'
								md='12'
								className='mt-0 d-flex flex-column formWidth'
							>
								<h1 className='text-center fw-bold mx-1 mx-md-4 mt-1'>
									Prospective Employer Sign up
								</h1>

								<div className='d-flex justify-content-center mt-2 mb-3'>
									<h6 className='mr-3 pt-1'>I might host</h6>
									<div className='d-flex allign-items-center mx-3'>
										<Input
											className='radioStyle'
											onChange={() =>
												handleStatesChange('school')
											}
											checked={ischeckedState == 'school'}
											type='radio'
										/>
										<h6 className='ml-1 pt-1'>
											High School Athlete
										</h6>
									</div>
									<div className='d-flex allign-items-center mx-3'>
										<Input
											className='radioStyle'
											onChange={() =>
												handleStatesChange('college')
											}
											checked={
												ischeckedState == 'college'
											}
											type='radio'
										/>
										<h6 className='ml-1 pt-1'>
											College Athlete
										</h6>
									</div>
								</div>
								<Form
									onSubmit={e => {
										e.preventDefault();
										let collegeData = {
											name: name,
											email: email,
											role: 'employer',
											state: state,
											city: city,
											institute: institute,
											sportLevel: ischeckedState,
											internshipDuration:
												internshipDuration,
											// company_name: name,
											internship_when:
												internshipDurationWhen,
											internship_where:
												internshipDurationWhere,
											internship_what:
												internshipDurationWhat,
											travel_assest: providerTravelAssist,
											accommodation_assest:
												accommodationsAssist,
											meal_assist: mealAssist,
											preferred_Candidate:
												preferredCandidate,
											contact: contact,
										};
										let schoolData = {
											name: name,
											email: email,
											role: 'employer',
											state: state,
											county: county,
											institute: institute,
											sportLevel: ischeckedState,
											// company_name: companyName,
											internshipDuration:
												internshipDuration,
											internship_when:
												internshipDurationWhen,
											internship_where:
												internshipDurationWhere,
											internship_what:
												internshipDurationWhat,
											travel_assest: providerTravelAssist,
											accommodation_assest:
												accommodationsAssist,
											meal_assist: mealAssist,
											preferred_Candidate:
												preferredCandidate,
											contact: contact,
										};

										// console.log(schoolData, 'SchoolData');
										// console.log(collegeData, 'CollegeData');

										ischeckedState == 'college'
											? dispatch(
													companyRegisterCollege(
														collegeData,
														password,
														() => {
															history.push('/');
														}
													)
											  )
											: dispatch(
													companyRegisterSchool(
														schoolData,
														password,
														() => {
															history.push('/');
														}
													)
											  );
									}}
								>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Company Name:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											// value={name}
											onChange={e =>
												handleNameCheck(e.target.value)
											}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Email:
										</Label>
										<Input
											required
											type='email'
											className='input-text pl-0'
											value={email}
											onChange={e =>
												setEmail(e.target.value)
											}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Password:
										</Label>
										<div className='paswordOuter'>
											<Input
												required
												type={
													showPassword
														? 'text'
														: 'password'
												}
												// value={password}
												className='input-text pl-0'
												onChange={e =>
													setPassword(e.target.value)
												}
											/>
											{password.length > 0 ? (
												<i
													className='fas fa-eye pointer eyeIcon'
													onClick={handleShowPassword}
												></i>
											) : null}
										</div>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											State:
										</Label>
										<Autocomplete
											{...defaultStateProps}
											id='auto-complete1'
											autoComplete
											includeInputInList
											disableClearable={true}
											value={state}
											disableListWrap
											freeSolo
											onChange={(e, val) => {
												setState(val);
												setCity('');
												setCounty('');
												setInstitute('');
											}}
											renderInput={params => (
												<TextField
													className={
														classes.auto_input
													}
													{...params}
													variant='standard'
												/>
											)}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											{ischeckedState == 'college'
												? 'City'
												: 'County'}
										</Label>
										<Autocomplete
											{...defaultCityProps}
											id='auto-complete2'
											autoComplete
											includeInputInList
											disableClearable={true}
											disableListWrap
											value={
												ischeckedState == 'college'
													? city
													: county
											}
											freeSolo
											onChange={(e, val) =>
												handleCityOrCounty(val)
											}
											renderInput={params => (
												<TextField
													className={
														classes.auto_input
													}
													{...params}
													variant='standard'
												/>
											)}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Institute:
										</Label>
										<Autocomplete
											{...defaultInstituteProps}
											id='auto-complete3'
											autoComplete
											includeInputInList
											value={institute}
											disableClearable={true}
											disableListWrap
											freeSolo
											onChange={(e, val) =>
												setInstitute(val)
											}
											renderInput={params => (
												<TextField
													className={
														classes.auto_input
													}
													{...params}
													variant='standard'
												/>
											)}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Internship Duration
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											onChange={e =>
												setInternshipDuration(
													e.target.value
												)
											}
										/>

										<Label className='labelText mb-0'>
											When
										</Label>
										<Input
											required
											type='date'
											className='input-text pl-0'
											// value={email}
											onChange={e =>
												setInternshipDurationWhen(
													e.target.value
												)
											}
										/>
										<Label className='labelText mb-0'>
											Where
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											// value={email}
											onChange={e =>
												setInternshipDurationWhere(
													e.target.value
												)
											}
										/>
										<Label className='labelText mb-0'>
											What
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											// value={email}
											onChange={e =>
												setInternshipDurationWhat(
													e.target.value
												)
											}
										/>
									</div>
									<div className='form-group  form-box mb-1'>
										<div className='justify-content-between'>
											<Label className='labelText mb-0'>
												Provide Travel Assist:
											</Label>

											{/* <div className='form-group form-box mb-1'> */}
											<div className='justify-content-between ml-4'>
												<Input
													type='radio'
													// checked={gender == 'Male'}
													id='travel-assist'
													name='Travel-assist'
													// value='yes'
													required
													onChange={() =>
														setProviderTravelAssist(
															true
														)
													}
												/>
												<Label
													for='travel-assist'
													// className={`auth-label ${
													// 	gender == 'Male'
													// 		? 'text-primary'
													// 		: ''
													// }`}
												>
													Yes
												</Label>
											</div>

											<div className='justify-content-between ml-4'>
												<Input
													type='radio'
													// checked={gender == 'Male'}
													id='travel-assist'
													name='Travel-assist'
													value='No'
													required
													onChange={() =>
														setProviderTravelAssist(
															false
														)
													}
												/>
												<Label
													for='travel-assist'
													// className={`auth-label ${
													// 	gender == 'Male'
													// 		? 'text-primary'
													// 		: ''
													// }`}
												>
													No
												</Label>
											</div>
										</div>
									
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Accommodations Assist:
										</Label>
										<div className='justify-content-between ml-4'>
											<Input
												type='radio'
												// checked={gender == 'Male'}
												id='accommodation-assist'
												name='accommodation-assist'
												value='yes'
												required
												onClick={() =>
													setAccommodationAssist(
														true
													)
												}
											/>
											<Label
												for='accommodation-assist'
												// className={`auth-label ${
												// 	gender == 'Male'
												// 		? 'text-primary'
												// 		: ''
												// }`}
											>
												Yes
											</Label>
										</div>

										<div className='justify-content-between ml-4'>
											<Input
												type='radio'
												// checked={gender == 'Male'}
												id='accommodation-assist'
												name='accommodation-assist'
												value='No'
												required
												onClick={() =>
													setAccommodationAssist(
														false
													)
												}
											/>
											<Label
												for='accommodation-assist'
												// className={`auth-label ${
												// 	gender == 'Male'
												// 		? 'text-primary'
												// 		: ''
												// }`}
											>
												No
											</Label>
										</div>
									</div>
									{/* </div> */}
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Meals Assist:
										</Label>
										
										<div className='justify-content-between ml-4'>
											<Input
												type='radio'
												// checked={gender == 'Male'}
												id='meals-assist'
												name='Meals-assist'
												value='yes'
												required
												onClick={() =>
													setMealAssist(
														true
													)
												}
											/>
											<Label
												for='meals-assist'
												// className={`auth-label ${
												// 	gender == 'Male'
												// 		? 'text-primary'
												// 		: ''
												// }`}
											>
												Yes
											</Label>
										</div>

										<div className='justify-content-between ml-4'>
											<Input
												type='radio'
												// checked={gender == 'Male'}
												id='meals-assist'
												name='Meals-assist'
												value='No'
												required
												onClick={() =>
													setMealAssist(
														false
													)
												}
											/>
											<Label
												for='Meals-assist'
												// className={`auth-label ${
												// 	gender == 'Male'
												// 		? 'text-primary'
												// 		: ''
												// }`}
											>
												No
											</Label>
										</div>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Preferred Candidate:
										</Label>
										<Input
											required
											type='textarea'
											className='input-text pl-0 h-100'
											onChange={e =>
												setPreferredCandidate(
													e.target.value
												)
											}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Contact:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											onChange={e =>
												setContact(e.target.value)
											}
										/>
									</div>
									<div className='registerBtn'>
										<Button
											className='mt-3 btn-success'
											size='lg'
											disabled={!email || !password}
										>
											{loading ? (
												<Spinner size='lg' />
											) : (
												'Register'
											)}
										</Button>
									</div>
								</Form>
								{/* New Form */}
								{/* <Form
									onSubmit={e => {
										e.preventDefault();
										let addObj = {
											company_name: companyName,
											internship_when:
												internshipDurationWhen,
											internship_where:
												internshipDurationWhere,
											internship_what:
												internshipDurationWhat,
											travel_assest: providerTravelAssist,
											accommodation_assest:
												accommodationsAssist,
											meal_assist: mealAssist,
											preferred_Candidate:
												preferredCandidate,
											contact: contact,
										};
										dispatch(athleteAdded(addObj));
									}}
								>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Company Name:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											// value={name}
											onChange={e =>
												setCompanyName(e.target.value)
											}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Internship Duration
										</Label>
										<br />
										<Label className='labelText mb-0'>
											When
										</Label>
										<Input
											required
											type='date'
											className='input-text pl-0'
											// value={email}
											onChange={e =>
												setInternshipDurationWhen(
													e.target.value
												)
											}
										/>
										<Label className='labelText mb-0'>
											Where
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											// value={email}
											onChange={e =>
												setInternshipDurationWhere(
													e.target.value
												)
											}
										/>
										<Label className='labelText mb-0'>
											What
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											// value={email}
											onChange={e =>
												setInternshipDurationWhat(
													e.target.value
												)
											}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Provide Travel Assist:
										</Label>
										{/* <div className='paswordOuter'> 
										<Input
											required
											type='date'
											className='input-text pl-0'
											onChange={e =>
												setProviderTravelAssist(
													e.target.value
												)
											}
										/>
										{/* </div>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Accommodations Assist:
										</Label>
										<Input
											required
											type='date'
											className='input-text pl-0'
											onChange={e =>
												setAccommodationAssist(
													e.target.value
												)
											}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Meals Assist:
										</Label>
										<Input
											required
											type='date'
											className='input-text pl-0'
											onChange={e =>
												setMealAssist(e.target.value)
											}
										/>
									</div>

									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Preferred Candidate:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											onChange={e =>
												setPreferredCandidate(
													e.target.value
												)
											}
										/>
									</div>
									<div className='form-group form-box mb-1'>
										<Label className='labelText mb-0'>
											Contact:
										</Label>
										<Input
											required
											type='text'
											className='input-text pl-0'
											onChange={e =>
												setContact(e.target.value)
											}
										/>
									</div>
									<div className='registerBtn'>
										<Button
											className='mt-3 btn-success'
											size='lg'
											// disabled={
											// 	!name || !email || !password
											// }
										>
											{athleteAddedLoading ? (
												<Spinner size='lg' />
											) : (
												'Register'
											)}
										</Button>
									</div>
								</Form> */}
							</Col>

							<Col
								lg='6'
								md='12'
								className='order-1 order-lg-2 d-flex align-items-center justify-content-center mt-2 mt-lg-0'
							>
								<img
									style={{ borderRadius: '25px' }}
									src={art}
									className='img-fluid'
									alt='ing'
								/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Container>
		</>
	);
};

export default CompanyRegister;
