import React, { useState } from 'react';
import ChatViewBody from '../components/ChatViewBody';
import ChatViewHeader from '../components/ChatViewHeader';
import defaultImg from '../assets/img/bgImg.jpg';
import { Col, Container, Row } from 'reactstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	allMessageRead,
	getAllChatsById,
	getAllUnreadMsgs,
	getMessageById,
} from '../store/actions/companyChatAction';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';

const Chat = () => {
	const dispatch = useDispatch();
	// const chatRef = useRef();
	const [isChatOpn, setIsChatOpen] = useState(false);
	const [receiverId, setReceiverId] = useState('');
	const [receiverName, setReceiverName] = useState('');
	const [searchMsg, setSearchMsg] = useState('');

	const { chats, loading, unSub, unReadMessages } = useSelector(
		state => state.companyChat
	);

	const { user, uid } = useSelector(state => state.authUser);

	const history = useHistory();
	// useEffect(() => {
	// 	if (user.hasOwnProperty('m4f_guardian_contact') === false) {
	// 		history.push('/terms-and-conditions');
	// 	}
	// }, []);

	// const getUserNameById = Data => {
	// 	let filterUid = Data.filter(ele => ele != uid);
	// 	let findUser =
	// 		chats &&
	// 		chats.find(ele => ele.partId == filterUid?.[0]);
	// 	if (findUser) {
	// 		return findUser;
	// 	}
	// };

	// const getUnreadMessageLength = id => {

	// 	let temp = unReadMessages?.filter(msg => msg.sender_id == id);
	// 	if (temp) {
	// 		return temp.length;
	// 	} else {
	// 		return 0;
	// 	}
	// };

	const handleMessages = async (Id, UidData, data) => {
		let filterUid = UidData.filter(ele => ele != uid);
		setReceiverId(filterUid?.[0]);
		setReceiverName(data);
		if (unSub != null) {
			await unSub();
		}
		dispatch(getMessageById(Id));
		dispatch(allMessageRead(Id));
		dispatch(getAllChatsById(uid));
		setIsChatOpen(true);
	};
	// useEffect(() => {
	// 	chatRef.current = chats;
	// }, [chats]);

	// console.log(chats.length);
	// console.log(chatRef.current.length);
	// console.log(chatRef);
	// if (chats?.length != chatRef.current?.length) {
	// 	dispatch(getAllUnreadMsgs(uid));
	// }
	useEffect(() => {
		dispatch(getAllChatsById(uid));
		// dispatch(getAllUnreadMsgs(uid));
	}, []);
	return (
		<Container fluid>
			<Row>
				<Col align='center' className='mt-4 mb-2'>
					<div className='ChatLeft'>
						<div className='header d-flex justify-content-between'>
							<p>
								<strong>Messages</strong>
							</p>
							<i className='fas fa-ellipsis-v'></i>
						</div>
						<div className='chat'>
							<Row className='m-0 p-0'>
								<Col className=' p-0' lg={3} md={3} sm={12}>
									<div className='Middle rightBorder'>
										<div className='inputOuter'>
											<input
												placeholder='Search Messages'
												onChange={e =>
													setSearchMsg(e.target.value)
												}
											/>
										</div>
									</div>
									<div className='profileOuter'>
										{chats?.length > 0 &&
											chats
												.filter(chat => {
													if (searchMsg == '') {
														return chat;
													} else if (
														chat.chatUser.first_name
															?.toLowerCase()
															.includes(
																searchMsg.toLowerCase()
															) ||
														chat.chatUser.last_name
															?.toLowerCase()
															.includes(
																searchMsg.toLowerCase()
															)
													) {
														return chat;
													}
												})
												.map((ele, index) => {
													return (
														<div
															className='profile mt-2 d-flex justify-content-between align-items-center'
															key={index}
														>
															<div
																className='d-flex pointer align-items-center'
																onClick={() =>
																	handleMessages(
																		ele.id,
																		ele.participants,
																		ele.chatUser
																	)
																}
															>
																<img
																	className='mt-1'
																	src={
																		ele
																			.chatUser
																			.image_url
																			? ele
																					.chatUser
																					.image_url
																			: defaultImg
																	}
																/>
																<h5 className='pt-2 pl-3 text-left'>
																	{ele.chatUser
																		? ele
																				.chatUser
																				.first_name +
																		  ' ' +
																		  ele
																				.chatUser
																				.last_name
																		: 'N/A'}
																</h5>
															</div>
															{ele.unreadMsg
																?.length > 0 ? (
																<div className='chatCountCircle'>
																	{
																		ele
																			.unreadMsg
																			?.length
																	}
																</div>
															) : null}
															{/* {getUnreadMessageLength(
																ele.partId
															) != 0 ? (
																<div className='chatCountCircle'>
																	{getUnreadMessageLength(
																		ele.partId
																	)}
																</div>
															) : null} */}
														</div>
													);
												})}
									</div>
								</Col>
								<Col lg={9} md={9} sm={12} className='p-0'>
									<div className='Middle'>
										<div className='outerHeader'>
											{isChatOpn ? (
												<ChatViewHeader
													// receiverIdParam={receiverIdParam}
													// receiverId={receiverId}
													receiverName={receiverName}
												/>
											) : (
												''
											)}
										</div>
									</div>

									{isChatOpn ? (
										<ChatViewBody
											receiverId={receiverId}
											// receiverIdParam={receiverIdParam}
										/>
									) : chats.length > 0 ? (
										''
									) : (
										<p>No Conversation Yet</p>
									)}
								</Col>
							</Row>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Chat;
