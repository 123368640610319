import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import React from 'react';

function Auth(props) {
	let { uid, user } = useSelector(state => state.authUser);
	const history = useHistory();

	if (uid) {
		console.log(user?.role, 'user.ROle');
		if (user?.role == 'athlete') {
			//  user
			// /terms-and-conditions
			if (
				user?.hasOwnProperty('m4f_online_persons_not_trustworthy') ===
				false
			) {
				history.push('/terms-and-conditions');
			} else {
				history.push('/individual-dashboard');
			}
		} else if (user?.role == 'employer') {
			history.push('/companyDashboard');
		} else {
			history.push('/login');
		}
	}
	return (
		<>
			{/* <header>Auth Header</header> */}
			{props.children}
			{/* <footer>Auth Footer</footer> */}
		</>
	);
}

export default Auth;
