import React, { useState } from "react";
import art from "../../assets/img/login-art.png";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Input, Form, Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { companyLogin, userlogin } from "../../store/actions/authAction";

function Login() {
  const dispatch = useDispatch();
  let auth = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ischeckedState, setIsCheckedState] = useState("user");
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleStatesChange = (e) => {
    setIsCheckedState(e);
    setPassword("");
    setEmail("");
  };

  return (
    <>
      <div className="loginMain">
        <Container fluid className="px-5  my-5 h-custom">
          <Row>
            <Col col="10" md="7" className="container-fluid">
              <div>
                <h3 className="font-weight-bold  text-center">
                  Starting In 2024
                </h3>
              </div>
              <img src={art} className="img-fluid" alt="art" />
            </Col>

            <Col
              col="4"
              md="4"
              className="mt-5 pt-5 card shadow-lg login__rounded"
            >
              <div className="d-flex flex-row align-items-center justify-content-center">
                <p className="h1 fw-normal mb-0 me-3">Sign in</p>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <div className="d-flex allign-items-center mx-3">
                  <Input
                    className="radioStyle"
                    onChange={() => handleStatesChange("user")}
                    checked={ischeckedState == "user"}
                    type="radio"
                  />
                  <h6 className="ml-1 pt-1">User</h6>
                </div>
                <div className="d-flex allign-items-center mx-3">
                  <Input
                    className="radioStyle"
                    onChange={() => handleStatesChange("company")}
                    checked={ischeckedState == "company"}
                    type="radio"
                  />
                  <h6 className="ml-1 pt-1">Company</h6>
                </div>
              </div>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  let creds = {
                    email: email,
                    password: password,
                  };
                  if (ischeckedState == "user") {
                    dispatch(userlogin(creds));
                  } else {
                    dispatch(companyLogin(creds));
                  }
                }}
              >
                <div>
                  <Input
                    required
                    className="mb-4 mt-4 mb-2"
                    placeholder="email@address.com"
                    id="formControlLg"
                    value={email}
                    type="email"
                    size="lg"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="paswordOuter">
                    <Input
                      wrapperClass="mb-4"
                      placeholder="Password"
                      id="formControlLg"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      size="lg"
                    />
                    {password.length > 0 ? (
                      <i
                        className="fas fa-eye pointer eyeIcon"
                        onClick={handleShowPassword}
                      ></i>
                    ) : null}
                  </div>

                  <div className="text-center text-md-start mt-4 pt-2">
                    <Button
                      className="btn-success mb-0 px-5"
                      size="lg"
                      disabled={!email || !password}
                    >
                      {" "}
                      {auth.loading ? <Spinner size="lg" /> : "Login"}
                    </Button>
                    <p className="small fw-bold mt-2 pt-1 mb-2">
                      Don't have an account?{" "}
                      <Link to="/" className="link-danger">
                        Register
                      </Link>
                    </p>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
