import React from 'react';

const ChatViewHeader = props => {
	return (
		<div className='d-flex align-items-center'>
			<h5 className='mr-auto ml-2 text-left'>
				{props.text == 'individual'
					? props.receiverName.name
					: props.receiverName.first_name +
					  ' ' +
					  props.receiverName.last_name}
			</h5>
			{/* <div className='d-flex mr-4'>
				<div className='mr-3'>
					<i className='fas fa-video IconCircle'></i>
				</div>
				<div>
					<i className='fas fa-ellipsis-v IconCircle'></i>
				</div>
			</div> */}
		</div>
	);
};

export default ChatViewHeader;
