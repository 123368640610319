import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultImg from "../assets/img/bgImg.jpg";

import firebase from "../config/firebase";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
  Spinner,
} from "reactstrap";
import {
  getCollegeCity,
  getSchoolCounty,
  fetchCollegeInstitute,
  fetchSchoolInstitute,
} from "../store/actions/locationAction";
import {
  fetchDesignateAlmaMatterCompany,
  fetchStateUSER,
} from "../store/actions/designateAlmaAction";
import {
  createChat,
  createMessage,
  getAllChatsById,
} from "../store/actions/companyChatAction";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const DesignateAlmaMatter = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");

  const [chatModal, setChatModal] = useState(false);
  const [receiverId, setReceiverId] = useState("");

  const [search, setSearch] = useState("");

  const { user, uid } = useSelector((state) => state.authUser);
  const { designaleAlmaData } = useSelector((state) => state.designate);

  const { chats, loading } = useSelector((state) => state.companyChat);
  const [filter, setFilter] = useState({
    baseFilter: "",
    state: "",
    baseState: "",
    city: "",
    county: "",
    school: "",
  });

  const handleChatModal = () => {
    setChatModal(!chatModal);
  };
  const { cities, counties, collegeInstitutes, schoolInstitutes } = useSelector(
    (state) => state.location
  );

  const {
    designaleAlmaUserData,
    stateusers,
    stateCity,
    countyBasedOnState,
    allSchools,
    SchoolFromCities,
    SchoolFromCounties,
  } = useSelector((state) => state.designate);

  const handleMessageBtn = (id) => {
    setChatModal(true);
    setReceiverId(id);
  };
  const handleChange = (e) => {
    setFilter((ev) => ({
      ...ev,
      [e.target.name]: e.target.value,
    }));
  };

  let filterFunction = () => {
    return (
      designaleAlmaData &&
      designaleAlmaData
        .filter((data) => {
          if (
            (filter.baseFilter === "" ||
              filter.baseFilter === "All" ||
              filter.baseFilter === "state") &&
            filter.state === ""
          ) {
            console.warn(designaleAlmaData);
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            data?.state.toUpperCase() === filter.state.toUpperCase() &&
            filter.city === "" &&
            filter.county === ""
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "city" &&
            filter.city !== "" &&
            filter.school === "" &&
            data?.city?.toUpperCase() === filter?.city?.toUpperCase()
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "county" &&
            filter.county !== "" &&
            filter.school === "" &&
            data?.county?.toUpperCase() === filter?.county?.toUpperCase()
          ) {
            console.log("state wala");
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "city" &&
            filter.city !== "" &&
            filter.school !== "" &&
            filter.baseState !== "All" &&
            data?.institute_name?.toUpperCase() ===
              filter?.school?.toUpperCase()
          ) {
            return data;
          } else if (
            filter.baseFilter === "state" &&
            filter.baseFilter != "all" &&
            filter.state !== "" &&
            filter.baseState === "county" &&
            filter.county !== "" &&
            filter.school !== "" &&
            data?.institute_name?.toUpperCase() ===
              filter?.school?.toUpperCase()
          ) {
            return data;
          }
        })
        .filter((data) => {
          if (search == "") {
            return data;
          } else if (
            data.first_name?.toLowerCase().includes(search.toLowerCase()) ||
            data.last_name?.toLowerCase().includes(search.toLowerCase())
          ) {
            return data;
          }
        })
    );
  };
  useEffect(() => {
    dispatch(fetchDesignateAlmaMatterCompany(user.institute));
  }, [user]);

  useEffect(() => {
    dispatch(getAllChatsById(uid));
  }, []);
  useEffect(() => {
    dispatch(fetchDesignateAlmaMatterCompany(user.institute_name));
  }, [user]);
  useEffect(() => {
    // if (selectState) {
    dispatch(fetchStateUSER());
    // }
  }, []);
  useEffect(() => {
    dispatch(getCollegeCity(filter.state));
    dispatch(getSchoolCounty(filter.state));
  }, [filter.state]);
  useEffect(() => {
    dispatch(fetchCollegeInstitute(filter.state, filter.city));
  }, [filter.state, filter.city]);
  useEffect(() => {
    dispatch(fetchSchoolInstitute(filter.state, filter.county));
  }, [filter.state, filter.county]);
  //

  const history = useHistory();

  useEffect(() => {
    if (!user.hasOwnProperty("m4f_online_persons_not_trustworthy")) {
      history.push("/terms-and-conditions");
    }
  }, []);
  return (
    <Container fluid className="designateAlmaMain">
      <h3 className="text-center mb-4 pt-2">
        <span style={{ borderBottom: "3px dashed black" }}>
          Designate Alma Matter
        </span>
      </h3>
      <Row className="mt-5 d-flex justify-content-center">
        <Col xs="12" md="4" className="pr-0">
          <Input
            className="form-control"
            placeholder="search by first name or last name"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>
      <Col xs="12" md="8" className="align-self-center">
        <Row>
          <Col xs="12" md="4" lg="2">
            <label for="baseFilter">Select</label>
            <Input
              type="select"
              name="baseFilter"
              value={filter.baseFilter}
              onChange={(e) => {
                handleChange(e);
                setFilter((ev) => ({
                  ...ev,
                  [e.target.name]: e.target.value,
                  state: "",
                  school: "",
                  city: "",
                  county: "",
                  baseState: "",
                }));
              }}
            >
              <option value="Select">Select</option>
              <option value="All">All</option>
              <option value="state">State</option>
            </Input>
          </Col>
          {filter.baseFilter === "state" ? (
            <Col xs="12" md="4" lg="2">
              <label for="state">Select State</label>
              <Input
                type="select"
                name="state"
                value={filter.state}
                onChange={(e) => {
                  handleChange(e);
                  setFilter((ev) => ({
                    ...ev,
                    [e.target.name]: e.target.value,

                    school: "",
                    city: "",
                    county: "",
                    baseState: "",
                  }));
                }}
              >
                <option value="Select">Select</option>
                {stateusers.length > 0 &&
                  stateusers?.map((state, index) => {
                    return (
                      <>
                        <option key={index} value={state.name}>
                          {state?.name}
                        </option>
                      </>
                    );
                  })}
              </Input>
            </Col>
          ) : (
            ""
          )}
          {filter.state.length > 0 && filter.state != "Select" ? (
            <Col xs="12" md="4" lg="2">
              <label for="state">Select Area</label>
              <Input
                type="select"
                name="baseState"
                value={filter.baseState}
                onChange={(e) => {
                  handleChange(e);
                  setFilter((ev) => ({
                    ...ev,
                    [e.target.name]: e.target.value,

                    school: "",
                    city: "",
                    county: "",
                  }));
                }}
              >
                <option value="Select">Select</option>
                <option value="All">All</option>
                {cities.length > 0 ? <option value="city">City</option> : ""}
                {counties.length > 0 ? (
                  <option value="county">County</option>
                ) : (
                  ""
                )}
              </Input>
            </Col>
          ) : (
            ""
          )}
          {filter.baseState === "city" ? (
            <Col xs="12" md="4" lg="2">
              <label for="city">Select City </label>
              <Input
                type="select"
                name="city"
                value={filter.city}
                onChange={(e) => {
                  handleChange(e);
                  setFilter((ev) => ({
                    ...ev,
                    [e.target.name]: e.target.value,

                    school: "",

                    county: "",
                  }));
                }}
              >
                <option value="Select">Select</option>
                {cities.map((data, idx) => {
                  return (
                    <option key={idx} value={data.city} className="d-col">
                      {data.city}
                    </option>
                  );
                })}
              </Input>
            </Col>
          ) : (
            ""
          )}
          {filter.baseState === "county" ? (
            <Col xs="12" md="4" lg="2">
              <label for="county">Select County </label>
              <Input
                type="select"
                name="county"
                value={filter.county}
                onChange={(e) => {
                  handleChange(e);
                  setFilter((ev) => ({
                    ...ev,
                    [e.target.name]: e.target.value,

                    school: "",
                    city: "",
                  }));
                }}
              >
                <option value="Select">Select </option>
                {counties?.map((element, ind) => {
                  return (
                    <option key={ind} value={element.county}>
                      {element.county}
                    </option>
                  );
                })}
              </Input>
            </Col>
          ) : (
            ""
          )}
          {(filter.city != "" || filter.county != "") &&
          filter.baseState != "All" ? (
            <Col xs="12" md="4" lg="2">
              <label for="school">Select School </label>
              <Input
                type="select"
                name="school"
                value={filter.school}
                onChange={handleChange}
              >
                <option value="Select">Select </option>
                {filter.city != "" && filter.county === ""
                  ? collegeInstitutes?.map((stat, index) => {
                      return stat?.institutes.map((data, idx) => {
                        return (
                          <option key={idx} value={data}>
                            {data}
                          </option>
                        );
                      });
                    })
                  : // (institutes?.map((element, ind) => {
                    //   return (
                    //     <option key={ind} value={element.school}>
                    //       {element.school}
                    //     </option>
                    //   );
                    // }))
                    schoolInstitutes?.map((stat, index) => {
                      return stat?.institutes.map((data, idx) => {
                        return (
                          <option key={idx} value={data}>
                            {data}
                          </option>
                        );
                      });
                    })}
              </Input>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Col>
      <Row>
        {filterFunction()?.length > 0 ? (
          filterFunction().map((ele, index) => {
            return (
              <Col xs={12} md="6" xl="4">
                <Card className="mb-4 mt-3 mb-xl-0" key={index}>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={4}>
                        <div className="text-center">
                          <img
                            src={ele.image_url ? ele.image_url : defaultImg}
                            className="img-fluid avatar"
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="text-center">
                          <h6>
                            <b>
                              {ele?.first_name} {ele?.last_name}
                            </b>
                          </h6>
                          <h6>
                            {ele.sportLevel == "school" ? (
                              <b>
                                ({ele.state},{ele.county})
                              </b>
                            ) : (
                              <b>
                                ({ele.state},{ele.city})
                              </b>
                            )}
                          </h6>
                          <h6>
                            <b>{ele.institute}</b>
                          </h6>
                          <a href={`mailto:${ele.email}`}>
                            <h6>
                              <b>{ele.email}</b>
                            </h6>
                          </a>
                        </div>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end mt-2">
                      <Button
                        color="success"
                        className="mr-2"
                        onClick={() => handleMessageBtn(ele.id)}
                      >
                        Message
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })
        ) : (
          <Col>
            <h2 className="text-center mt-3">
              No Designate Alma Matter to show
            </h2>
          </Col>
        )}
      </Row>

      {/* Modal For Send Message */}

      <Modal isOpen={chatModal} toggle={handleChatModal} centered>
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div>
              <i onClick={handleChatModal} className="fas fa-times"></i>
            </div>
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              let checkFilter =
                chats &&
                chats.filter((chat) => {
                  if (
                    chat.participants &&
                    chat.participants.find((par) => par == receiverId) &&
                    chat.participants.find((par) => par == uid)
                  ) {
                    return chat;
                  }
                });

              let chatObj = {
                created_at: firebase.firestore.Timestamp.now(),
                participants: [receiverId, uid],
              };
              let msgObj = {
                message: message,
                sender_id: uid,
                read: false,
              };
              // let chatNotificationObj = {
              // 	created_at: firebase.firestore.Timestamp.now(),
              // 	creator_id: uid,
              // 	notification:
              // 		user?.name + ' has sent you a new message',
              // 	type: 'chat_new_message',
              // 	read_by: [],
              // 	receiver_id: props.ele.id,
              // 	redirect_id: checkFilter[0].id,
              // };

              if (checkFilter.length > 0) {
                dispatch(createMessage(checkFilter[0].id, msgObj));
                setMessage("");
              } else {
                dispatch(createChat(chatObj, msgObj));
                setMessage("");
              }
              handleChatModal();
            }}
          >
            <div>
              <div className="">
                <Label className="labelText mt-3">Start a conversation:</Label>
              </div>
              <Input
                className="mt-2 ChatTextArea"
                type="textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="write your Message..."
                rows={5}
              />
            </div>

            <div className="d-flex mt-4 justify-content-end">
              <Button
                disabled={!message}
                className="bg-success border-0 mr-1 text-white"
                type="submit"
              >
                {loading ? <Spinner size="sm" /> : "Send"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default DesignateAlmaMatter;
