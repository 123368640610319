// Root Reducer

import { combineReducers } from 'redux';
import authUserReducer from './authUser';
import authReducer from './authReducer';
import jobReducer from './jobReducer';
import locationReducer from './locationReducer';
import designateAlmaReducer from './designateAlmaReducer';
import companyChatReducer from './companyChatReducer';

export let rootReducer = combineReducers({
	auth: authReducer,
	authUser: authUserReducer,
	jobs: jobReducer,
	location: locationReducer,
	designate: designateAlmaReducer,
	companyChat: companyChatReducer,
});

export default rootReducer;
