import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../config/firebase';
import {
	Button,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	Row,
} from 'reactstrap';
import AppliedJobs from '../components/AppliedJobs';
import MyJobsCard from '../components/MyJobsCard';
import SearchJobs from '../components/SearchJobs';
import {
	appliedJob,
	fetchAllAppliedJobs,
	fetchAllJobs,
	getMoreJobs,
} from '../store/actions/jobAction';
import {
	createChat,
	createMessage,
	getAllIndividualChatsById,
} from '../store/actions/companyChatAction';
import { useHistory } from 'react-router-dom';
import { userInfo } from '../store/actions/authAction';

const IndividualDashboard = () => {
	const dispatch = useDispatch();
	let history = useHistory();
	const { allAppliedJobs, allJobs, searchJobsResult } = useSelector(
		state => state.jobs
	);
	const { chats } = useSelector(state => state.companyChat);
	const { user, uid } = useSelector(state => state.authUser);
	const [file, setFile] = useState('');
	const [message, setMessage] = useState('');
	const [resumeModal, setResumeModal] = useState(false);
	const [receiverId, setReceiverId] = useState('');
	const [chatModal, setChatModal] = useState(false);
	const [jobId, setJobId] = useState('');

	let temparr = [];
	temparr = allAppliedJobs?.slice(0, 4);
	let showAllJobs =
		allJobs &&
		allJobs.filter(item => {
			return !allAppliedJobs?.find(elem => {
				return elem.job_id === item.id;
			});
		});

	const handleResumseModal = () => {
		setResumeModal(!resumeModal);
	};
	const handleChatModal = () => {
		setChatModal(!chatModal);
	};
	const handleMessageBtn = id => {
		setChatModal(true);
		setReceiverId(id);
	};
	const handleResumeModal = id => {
		setJobId(id);
		setResumeModal(true);
	};
	useEffect(() => {
		dispatch(fetchAllAppliedJobs(uid));
		dispatch(fetchAllJobs());
		dispatch(getAllIndividualChatsById(uid));
	}, []);

	if (searchJobsResult?.length > 0) {
		history.push('/job-search-result');
	}
	useEffect(() => {
		if (!user.hasOwnProperty('m4f_online_persons_not_trustworthy')) {
			history.push('/terms-and-conditions');
		}
	}, []);

	return (
		<>
			<Container fluid>
				<Row>
					<Col lg={3}>
						{temparr && temparr.length > 0
							? temparr.map((ele, index) => {
									return (
										<Row>
											<Col key={index}>
												<AppliedJobs ele={ele} />
											</Col>
										</Row>
									);
							  })
							: null}
					</Col>
					<Col lg={9}>
						<SearchJobs />
						<Row>
							{showAllJobs && showAllJobs.length > 0 ? (
								showAllJobs.map((ele, index) => {
									return (
										<Col lg='6' xl='12' key={index}>
											<div className='searchJobCard mb-3'>
												<div className='jobCardSet pt-3'>
													<MyJobsCard ele={ele} />
													<div className='d-flex align-items-center justify-content-end mt-2 pb-2'>
														<Button
															color='success'
															className='mr-2'
															onClick={() =>
																handleMessageBtn(
																	ele.creator_Id
																)
															}
														>
															Send Message To
															Employer
														</Button>
														<Button
															color='success'
															onClick={() =>
																handleResumeModal(
																	ele.id
																)
															}
														>
															Apply
														</Button>
													</div>
												</div>
											</div>
										</Col>
									);
								})
							) : (
								<Col>
									<h2 className='text-center mt-3'>
										No Jobs Yet
									</h2>
								</Col>
							)}
						</Row>
						{temparr.length > 0 ? (
							<div className='d-flex justify-content-center mb-2'>
								<Button
									className='bg-success border-0 mr-1 text-white'
									onClick={() => dispatch(getMoreJobs())}
								>
									load More
								</Button>
							</div>
						) : null}
						<div>
							<Button
								color='primary'
								className='float-left'
								onClick={() =>
									history.push('/terms-and-conditions')
								}
							>
								Terms and condition
							</Button>

							<Button
								color='primary'
								className='float-left ml-2'
								onClick={() =>
									history.push(
										'/ProspectiveEmployerInternship'
									)
								}
							>
								Employer Internship
							</Button>
						</div>
					</Col>
				</Row>
			</Container>

			{/* Modal For Select Resume */}

			<Modal isOpen={resumeModal} toggle={handleResumseModal} centered>
				<ModalBody>
					<div className='d-flex justify-content-end align-items-center'>
						{/* <div>
							<h5>Edit Job</h5>
						</div> */}
						<div>
							<i
								onClick={handleResumseModal}
								className='fas fa-times'
							></i>
						</div>
					</div>
					<Form className='editForm'>
						<FormGroup className='mb-1'>
							<Label className='labelText' for='title'>
								Select Resume:
							</Label>
							<Input
								type='file'
								required
								onChange={e => setFile(e.target.files[0])}
							/>
						</FormGroup>

						<div className='d-flex mt-4 justify-content-end'>
							<Button
								type='submit'
								className='bg-success border-0 mr-1'
								disabled={!file}
								onClick={e => {
									e.preventDefault();
									let obj = {
										file: file,
										job_id: jobId,
										user_id: uid,
									};
									dispatch(appliedJob(obj));
									setFile('');
									setResumeModal(false);
								}}
							>
								Submit
							</Button>{' '}
							<Button
								color='secondary'
								onClick={handleResumseModal}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>

			{/* Modal For Send Message */}

			<Modal isOpen={chatModal} toggle={handleChatModal} centered>
				<ModalBody>
					<div className='d-flex justify-content-end'>
						<div>
							<i
								onClick={handleChatModal}
								className='fas fa-times'
							></i>
						</div>
					</div>
					<Form
						onSubmit={e => {
							e.preventDefault();
							let checkFilter =
								chats &&
								chats.filter(chat => {
									if (
										chat.participants &&
										chat.participants.find(
											par => par == receiverId
										) &&
										chat.participants.find(
											par => par == uid
										)
									) {
										return chat;
									}
								});

							let chatObj = {
								created_at: firebase.firestore.Timestamp.now(),
								participants: [receiverId, uid],
							};
							let msgObj = {
								message: message,
								sender_id: uid,
								read: false,
							};

							if (checkFilter.length > 0) {
								dispatch(
									createMessage(checkFilter[0].id, msgObj)
								);
								setMessage('');
							} else {
								dispatch(createChat(chatObj, msgObj));
								setMessage('');
							}
							handleChatModal();
						}}
					>
						<div>
							<div className=''>
								<Label className='labelText mt-3'>
									Start a conversation:
								</Label>
							</div>
							<Input
								className='mt-2 ChatTextArea'
								type='textarea'
								value={message}
								onChange={e => setMessage(e.target.value)}
								placeholder='write your Message...'
								rows={5}
							/>
						</div>

						<div className='d-flex mt-4 justify-content-end'>
							<Button
								disabled={!message}
								className='bg-success border-0 mr-1 text-white'
								type='submit'
							>
								send
							</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</>
	);
};

export default IndividualDashboard;
