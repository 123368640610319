import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IndividualNavBar from '../components/IndividualNavBar';

const Main = props => {
	let { uid } = useSelector(state => state.authUser);
	const history = useHistory();

	if (!uid) {
		history.push('/');
	}
	return (
		<>
			{/* <header>Main Header</header> */}
			<IndividualNavBar />
			{props.children}
			{/* <footer>Main Footer</footer> */}
		</>
	);
};

export default Main;
