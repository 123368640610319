import React, { useEffect, useState } from 'react';
import {
	Col,
	Container,
	Row,
	Label,
	Input,
	Button,
	Form,
	Spinner,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { updateImg, updateProfilePicture } from '../store/actions/authAction';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { updateInfo } from '../store/actions/authAction';
import {
	fetchCollegeInstitute,
	fetchCollegeStates,
	fetchSchoolInstitute,
	fetchSchoolStates,
	getCollegeCity,
	getSchoolCounty,
} from '../store/actions/locationAction';

const UpdateProfile = () => {
	let { user, uid } = useSelector(state => state.authUser);
	const {
		collegeStates,
		schoolStates,
		cities,
		counties,
		collegeInstitutes,
		schoolInstitutes,
	} = useSelector(state => state.location);

	let dispatch = useDispatch();
	const [email, setEmail] = useState(user.email);
	const [first_name, setName] = useState('');
	const [last_name, setLastName] = useState('');
	const [youtubeURL, setYoutubeURL] = useState('');
	const [sportLevel, setSportLevel] = useState(false);
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [institute, setInstitute] = useState('');

	const [fields, setFields] = useState({
		nameEdit: false,
		lastNameEdit: false,
		sportLevelEdit: false,
		cityEdit: false,
		youtubeEdit: false,
		sportEdit: false,
		stateEdit: false,
		instituteEdit: false,
		collegeEdit: false,
	});
	const [image, setImage] = useState('');
	useEffect(() => {
		if (collegeStates.length == 0) {
			dispatch(fetchCollegeStates());
		}
		if (schoolStates.length == 0) {
			dispatch(fetchSchoolStates());
		}
	}, []);

	useEffect(() => {
		if (user.sportLevel == 'college') {
			dispatch(getCollegeCity(user.state));
		} else {
			dispatch(getSchoolCounty(user.state));
		}
	}, [user.state]);
	useEffect(() => {
		if (user.sportLevel == 'college') {
			dispatch(fetchCollegeInstitute(user.state, user.city));
		} else {
			dispatch(fetchSchoolInstitute(user.state, user.county));
		}
	}, [user.state, user.city, user.county]);

	let newSchools = [];
	const getInstitutes = () => {
		if (user.sportLevel == 'college') {
			collegeInstitutes.forEach(insti => {
				insti.institutes?.forEach(ele => {
					newSchools.push(ele);
				});
			});
		} else {
			schoolInstitutes.forEach(insti => {
				insti.institutes?.forEach(ele => {
					newSchools.push(ele);
				});
			});
		}
		if (newSchools) {
			return newSchools;
		}
	};

	const defaultStateProps = {
		options:
			user.sportLevel == 'college'
				? collegeStates?.map(insti => {
						return insti.name;
				  })
				: schoolStates?.map(insti => {
						return insti.name;
				  }),
		getOptionLabel: option => option,
	};
	const defaultCollegeProps = {
		options:
			user.sportLevel == 'college'
				? cities?.map(insti => {
						return insti.city;
				  })
				: counties?.map(insti => {
						return insti.county;
				  }),
		getOptionLabel: option => option,
	};

	const defaultInstituteProps = {
		options: getInstitutes(),
		getOptionLabel: option => option,
	};

	const fieldToggle = name => {
		let newField = JSON.parse(JSON.stringify(fields));
		for (let field of Object.keys(newField)) {
			if (field != name) newField[field] = false;
		}
		newField[name] = !newField[name];
		setFields(newField);
	};

	return (
		<>
			<Container fluid className='updateProfileMain mt-4 mt-lg-0'>
				<Row className='no-gutters justify-content-center'>
					<Col lg='10' md='12'>
						<div className='complaint-card mb-2 mt-2'>
							<div className='complaint-form'>
								<Row>
									<Col>
										<p className='edit-password-info'>
											Email ID:
										</p>
									</Col>
									<Col>
										<p className='edit-password-info'>
											{email}
										</p>
									</Col>
								</Row>

								<Row
									className={`${
										fields.youtubeEdit ? 'mt-5' : 'mt-0'
									}`}
								>
									<Col sm={12} md={6}>
										<p className='edit-password-info'>
											First Name:{' '}
										</p>
									</Col>
									<Col sm={12} md={6}>
										<div
											className={`${
												!fields.nameEdit
													? 'd-flex'
													: 'd-none'
											}`}
										>
											<p className='edit-password-info'>
												{user.first_name}
											</p>
											<i
												className='fa fa-pencil-alt mt-2 ml-2 pointer'
												onClick={() =>
													fieldToggle('nameEdit')
												}
											></i>
										</div>
										<Form
											className={`${
												fields.nameEdit
													? 'd-flex'
													: 'd-none'
											}`}
											onSubmit={e => {
												e.preventDefault();
												dispatch(
													updateInfo(
														uid,
														first_name,
														'name'
													)
												).then(res => {
													fieldToggle('nameEdit');
												});
											}}
										>
											<Input
												required
												className='custom-input mt-0'
												type='text'
												placeholder='Enter First Name'
												value={first_name}
												onChange={e =>
													setName(e.target.value)
												}
											/>
											<Button
												className='mr-1 ml-3'
												color='primary'
												type='submit'
											>
												<i className='fas fa-check'></i>
											</Button>
											<Button
												className=''
												color='danger'
												onClick={() =>
													fieldToggle('nameEdit')
												}
											>
												<i className='fas fa-times'></i>
											</Button>
										</Form>
									</Col>
								</Row>
								<Row
									className={`${
										fields.nameEdit ? 'mt-5' : 'mt-0'
									}`}
								>
									<Col sm={12} md={6}>
										<p className='edit-password-info'>
											Last Name:{' '}
										</p>
									</Col>
									<Col sm={12} md={6}>
										<div
											className={`${
												!fields.lastNameEdit
													? 'd-flex'
													: 'd-none'
											}`}
										>
											<p className='edit-password-info'>
												{user.last_name}
											</p>
											<i
												className='fa fa-pencil-alt mt-2 ml-2 pointer'
												onClick={() =>
													fieldToggle('lastNameEdit')
												}
											></i>
										</div>
										<Form
											className={`${
												fields.lastNameEdit
													? 'd-flex'
													: 'd-none'
											}`}
											onSubmit={e => {
												e.preventDefault();
												dispatch(
													updateInfo(
														uid,
														last_name,
														'last_name'
													)
												).then(res => {
													fieldToggle('lastNameEdit');
												});
											}}
										>
											<Input
												required
												className='custom-input mt-0'
												type='text'
												placeholder='Enter Last Name'
												value={last_name}
												onChange={e =>
													setLastName(e.target.value)
												}
											/>
											<Button
												className='mr-1 ml-3'
												color='primary'
												type='submit'
											>
												<i className='fas fa-check'></i>
											</Button>
											<Button
												className=''
												color='danger'
												onClick={() =>
													fieldToggle('lastNameEdit')
												}
											>
												<i className='fas fa-times'></i>
											</Button>
										</Form>
									</Col>
								</Row>
								<Row
									className={`${
										fields.cityEdit ? 'mt-5' : 'mt-0'
									}`}
								>
									<Col sm={12} md={6}>
										<p className='edit-password-info'>
											State:
										</p>
									</Col>
									<Col sm={12} md={6}>
										<div
											className={`${
												!fields.stateEdit
													? 'd-flex'
													: 'd-none'
											}`}
										>
											<p className='edit-password-info'>
												{user.state}
											</p>
											<i
												className='fa fa-pencil-alt mt-2 ml-2 pointer'
												onClick={() =>
													fieldToggle('stateEdit')
												}
											></i>
										</div>
										<Form
											onSubmit={e => {
												e.preventDefault();
												e.preventDefault();
												dispatch(
													updateInfo(
														uid,
														state,
														'state'
													)
												).then(res => {
													fieldToggle('stateEdit');
												});
											}}
										>
											<div
												className={`${
													fields.stateEdit
														? 'd-flex'
														: 'd-none'
												}`}
											>
												<Autocomplete
													{...defaultStateProps}
													id='auto-complete'
													className='w-100'
													autoComplete
													includeInputInList
													onChange={(e, val) =>
														setState(val)
													}
													renderInput={params => (
														<TextField
															{...params}
															required
															placeholder='Select State'
															variant='standard'
														/>
													)}
												/>
												<Button
													className='mr-1 ml-3'
													color='primary'
													type='submit'
												>
													<i className='fas fa-check'></i>
												</Button>
												<Button
													className=''
													color='danger'
													onClick={() =>
														fieldToggle('stateEdit')
													}
												>
													<i className='fas fa-times'></i>
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
								<Row
									className={`${
										fields.stateEdit ? 'mt-5' : 'mt-0'
									}`}
								>
									<Col sm={12} md={6}>
										<p className='edit-password-info mb-0'>
											{user.sportLevel == 'college'
												? 'City'
												: 'County'}
										</p>
									</Col>
									<Col sm={12} md={6}>
										<div
											className={`${
												!fields.collegeEdit
													? 'd-flex'
													: 'd-none'
											}`}
										>
											<p className='edit-password-info'>
												{user.sportLevel == 'college'
													? user.city
													: user.county}
											</p>
											<i
												className='fa fa-pencil-alt mt-2 ml-2 pointer'
												onClick={() =>
													fieldToggle('collegeEdit')
												}
											></i>
										</div>
										<Form
											onSubmit={e => {
												e.preventDefault();
												e.preventDefault();
												user.sportLevel == 'college'
													? dispatch(
															updateInfo(
																uid,
																state,
																'city'
															)
													  ).then(res => {
															fieldToggle(
																'collegeEdit'
															);
													  })
													: dispatch(
															updateInfo(
																uid,
																state,
																'county'
															)
													  ).then(res => {
															fieldToggle(
																'collegeEdit'
															);
													  });
											}}
										>
											<div
												className={`${
													fields.collegeEdit
														? 'd-flex'
														: 'd-none'
												}`}
											>
												<Autocomplete
													{...defaultCollegeProps}
													id='auto-complete'
													className='w-100'
													autoComplete
													includeInputInList
													onChange={(e, val) =>
														setState(val)
													}
													renderInput={params => (
														<TextField
															{...params}
															required
															placeholder='Select City'
															variant='standard'
														/>
													)}
												/>
												<Button
													className='mr-1 ml-3'
													color='primary'
													type='submit'
												>
													<i className='fas fa-check'></i>
												</Button>
												<Button
													className=''
													color='danger'
													onClick={() =>
														fieldToggle(
															'collegeEdit'
														)
													}
												>
													<i className='fas fa-times'></i>
												</Button>
											</div>
										</Form>
									</Col>
								</Row>

								<Row
									className={`${
										fields.stateEdit ? 'mt-5' : 'mt-0'
									}`}
								>
									<Col sm={12} md={6}>
										<p className='edit-password-info mb-0'>
											Institute
										</p>
									</Col>
									<Col sm={12} md={6}>
										<div
											className={`${
												!fields.instituteEdit
													? 'd-flex'
													: 'd-none'
											}`}
										>
											<p className='edit-password-info'>
												{user.institute}
											</p>
											<i
												className='fa fa-pencil-alt mt-2 ml-2 pointer'
												onClick={() =>
													fieldToggle('instituteEdit')
												}
											></i>
										</div>
										<Form
											onSubmit={e => {
												e.preventDefault();
												e.preventDefault();
												dispatch(
													updateInfo(
														uid,
														institute,
														'institute'
													)
												).then(res => {
													fieldToggle(
														'instituteEdit'
													);
												});
											}}
										>
											<div
												className={`${
													fields.instituteEdit
														? 'd-flex'
														: 'd-none'
												}`}
											>
												<Autocomplete
													{...defaultInstituteProps}
													id='auto-complete'
													className='w-100'
													autoComplete
													includeInputInList
													onChange={(e, val) =>
														setInstitute(val)
													}
													renderInput={params => (
														<TextField
															{...params}
															required
															placeholder='Select Institute'
															variant='standard'
														/>
													)}
												/>
												<Button
													className='mr-1 ml-3'
													color='primary'
													type='submit'
												>
													<i className='fas fa-check'></i>
												</Button>
												<Button
													className=''
													color='danger'
													onClick={() =>
														fieldToggle(
															'instituteEdit'
														)
													}
												>
													<i className='fas fa-times'></i>
												</Button>
											</div>
										</Form>
									</Col>
								</Row>

								<Row className='mt-4'>
									<Col md={6} sm={12}>
										<Label className='auth-label mb-0'>
											Current Image
										</Label>
										<br />
										<img
											src={user?.image_url}
											width='150px'
											height='150px'
										/>
									</Col>
									<Col sm={12} md={6}>
										<Form
											onSubmit={e => {
												e.preventDefault();
												let item = {
													id: uid,
													imgURL: user?.image_url,
													img: image,
												};
												dispatch(
													updateProfilePicture(item)
												);
												setImage('');
											}}
										>
											<Label className='auth-label mb-0'>
												Change Image
											</Label>
											<Input
												type='file'
												required
												onChange={e =>
													setImage(e.target.files[0])
												}
												accept='image/png, image/gif, image/jpeg'
												className='mt-4'
											/>
											<Label className='auth-label text-danger'>
												Max Size 1MB
											</Label>
											<br />
											<Button
												className='mt-3'
												color='primary'
												disabled={!image}
												type='submit'
											>
												Update
											</Button>
										</Form>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default UpdateProfile;
