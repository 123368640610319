import moment from 'moment';
import React from 'react';

const RecieveChat = props => {
	return (
		<>
			<div className='receiveChatOuter'>
				<div className='Recieve-msg'>
					{props.msgText ? (
						<p className='Recieve-msg-texts'>{props.msgText}</p>
					) : null}

					{props.messageFile_url ? (
						<p className='m-0'>
							<a href={props.messageFile_url} target='_blank'>
								view File
							</a>
						</p>
					) : null}
					<p className='Recieve-msg-time'>
						{moment.unix(props.created_at.seconds).fromNow()}
					</p>
				</div>
			</div>
		</>
	);
};

export default RecieveChat;
