import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardText,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Progress,
	Row,
} from 'reactstrap';

function Dashboard() {
	let { uid, user } = useSelector(state => state.authUser);
	const history = useHistory();
	// useEffect(() => {
	// 	if (user.hasOwnProperty('m4f_guardian_contact') === false) {
	// 		history.push('/terms-and-conditions');
	// 	}
	// }, []);
	return (
		<>
			<section style={{ backgroundColor: '#eee' }}>
				<Container className='py-5'>
					<Row>
						<Col>
							<Breadcrumb className='bg-light rounded-3 p-3 mb-4'>
								<BreadcrumbItem>
									<a href='#'>Home</a>
								</BreadcrumbItem>
								<BreadcrumbItem>
									<a href='#'>User</a>
								</BreadcrumbItem>
								<BreadcrumbItem active>
									User Profile
								</BreadcrumbItem>
							</Breadcrumb>
						</Col>
					</Row>

					<Row>
						<Col lg='4'>
							<Card className='mb-4'>
								<CardBody className='text-center'>
									<img
										src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp'
										alt='avatar'
										className='rounded-circle'
										style={{ width: '150px' }}
										fluid
									/>
									<p className='text-muted mb-1'>
										Full Stack Developer
									</p>
									<p className='text-muted mb-4'>
										Bay Area, San Francisco, CA
									</p>
									<div className='d-flex justify-content-center mb-2'>
										<Button>Follow</Button>
										<Button outline className='ms-1'>
											Message
										</Button>
									</div>
								</CardBody>
							</Card>

							<Card className='mb-4 mb-lg-0'>
								<CardBody className='p-0'>
									<ListGroup flush className='rounded-3'>
										<ListGroupItem className='d-flex justify-content-between align-items-center p-3'>
											<i
												fas
												icon='globe fa-lg text-warning'
											/>
											<CardText>
												https://bootstrap.com
											</CardText>
										</ListGroupItem>
										<ListGroupItem className='d-flex justify-content-between align-items-center p-3'>
											<i
												fab
												icon='github fa-lg'
												style={{ color: '#333333' }}
											/>
											<CardText>mdbootstrap</CardText>
										</ListGroupItem>
										<ListGroupItem className='d-flex justify-content-between align-items-center p-3'>
											<i
												fab
												icon='twitter fa-lg'
												style={{ color: '#55acee' }}
											/>
											<CardText>@mdbootstrap</CardText>
										</ListGroupItem>
										<ListGroupItem className='d-flex justify-content-between align-items-center p-3'>
											<i
												fab
												icon='instagram fa-lg'
												style={{ color: '#ac2bac' }}
											/>
											<CardText>mdbootstrap</CardText>
										</ListGroupItem>
										<ListGroupItem className='d-flex justify-content-between align-items-center p-3'>
											<i
												fab
												icon='facebook fa-lg'
												style={{ color: '#3b5998' }}
											/>
											<CardText>mdbootstrap</CardText>
										</ListGroupItem>
									</ListGroup>
								</CardBody>
							</Card>
						</Col>
						<Col lg='8'>
							<Card className='mb-4'>
								<CardBody>
									<Row>
										<Col sm='3'>
											<CardText>Full Name</CardText>
										</Col>
										<Col sm='9'>
											<CardText className='text-muted'>
												Johnatan Smith
											</CardText>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col sm='3'>
											<CardText>Email</CardText>
										</Col>
										<Col sm='9'>
											<CardText className='text-muted'>
												example@example.com
											</CardText>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col sm='3'>
											<CardText>Phone</CardText>
										</Col>
										<Col sm='9'>
											<CardText className='text-muted'>
												(097) 234-5678
											</CardText>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col sm='3'>
											<CardText>Mobile</CardText>
										</Col>
										<Col sm='9'>
											<CardText className='text-muted'>
												(098) 765-4321
											</CardText>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col sm='3'>
											<CardText>Address</CardText>
										</Col>
										<Col sm='9'>
											<CardText className='text-muted'>
												Bay Area, San Francisco, CA
											</CardText>
										</Col>
									</Row>
								</CardBody>
							</Card>

							<Row>
								<Col md='6'>
									<Card className='mb-4 mb-md-0'>
										<CardBody>
											<CardText className='mb-4'>
												<span className='text-primary font-italic me-1'>
													assigment
												</span>{' '}
												Project Status
											</CardText>
											<CardText
												className='mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Web Design
											</CardText>
											<Progress
												className='rounded'
												width={80}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Website Markup
											</CardText>
											<Progress
												className='rounded'
												width={72}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												One Page
											</CardText>
											<Progress
												className='rounded'
												width={89}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Mobile Template
											</CardText>
											<Progress
												className='rounded'
												width={55}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Backend API
											</CardText>
											<Progress
												className='rounded'
												width={66}
												valuemin={0}
												valuemax={100}
											/>
										</CardBody>
									</Card>
								</Col>

								<Col md='6'>
									<Card className='mb-4 mb-md-0'>
										<CardBody>
											<CardText className='mb-4'>
												<span className='text-primary font-italic me-1'>
													assigment
												</span>{' '}
												Project Status
											</CardText>
											<CardText
												className='mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Web Design
											</CardText>
											<Progress
												className='rounded'
												width={80}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Website Markup
											</CardText>
											<Progress
												className='rounded'
												width={72}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												One Page
											</CardText>
											<Progress
												className='rounded'
												width={89}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Mobile Template
											</CardText>
											<Progress
												className='rounded'
												width={55}
												valuemin={0}
												valuemax={100}
											/>

											<CardText
												className='mt-4 mb-1'
												style={{ fontSize: '.77rem' }}
											>
												Backend API
											</CardText>
											<Progress
												className='rounded'
												width={66}
												valuemin={0}
												valuemax={100}
											/>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
}

export default Dashboard;
