import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import MyJobsCard from '../components/MyJobsCard';
import { fetchAllAppliedJobs } from '../store/actions/jobAction';

const AllAppliedJobs = () => {
	const dispatch = useDispatch();
	const { allAppliedJobs } = useSelector(state => state.jobs);
	const { user, uid } = useSelector(state => state.authUser);

	useEffect(() => {
		if (allAppliedJobs.length == 0) {
			dispatch(fetchAllAppliedJobs(uid));
		}
	}, []);

	const history = useHistory();

	useEffect(() => {
		if (!user.hasOwnProperty('m4f_online_persons_not_trustworthy')) {
			history.push('/terms-and-conditions');
		}
	}, []);
	return (
		<Container fluid className='myJobsMain'>
			<h3 className='text-center mb-4 pt-2'>
				<span style={{ borderBottom: '3px dashed black' }}>
					Applied Jobs
				</span>
			</h3>
			<Row>
				{allAppliedJobs && allAppliedJobs.length > 0 ? (
					allAppliedJobs.map((ele, index) => {
						return (
							<Col lg='6' xl='4'>
								<Card className='mb-4 mt-3 mb-xl-0'>
									<CardBody>
										<div key={index} className='jobCardSet'>
											<div className='d-flex justify-content-end'>
												<span
													className={
														ele?.jobdata?.status ==
														'active'
															? 'badge p-2 badge-success'
															: 'badge p-2 badge-danger'
													}
												>
													{ele.jobdata.status}
												</span>
											</div>
											<MyJobsCard ele={ele?.jobdata} />
											<div className='d-flex align-items-center justify-content-between mt-3'>
												<a
													href={ele.file_url?.url}
													target='_blank'
												>
													<h5>
														{/* Click Here To See Resume */}
														{
															ele.file_url
																?.file_name
														}
													</h5>
												</a>
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						);
					})
				) : (
					<Col>
						<h2 className='text-center mt-3'>
							You Have No Jobs Yet
						</h2>
					</Col>
				)}
			</Row>
		</Container>
	);
};

export default AllAppliedJobs;
