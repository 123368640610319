import { Autocomplete, FormGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Form,
	Input,
	Label,
	Modal,
	ModalBody,
	Row,
} from 'reactstrap';
import MyJobsCard from '../components/MyJobsCard';
import { fetchMyJobs, updateJob } from '../store/actions/jobAction';

const MyJobs = () => {
	const { myJobs } = useSelector(state => state.jobs);
	const [editID, setEditID] = useState('');
	const [editPostModal, setEditPostModal] = useState(false); //Modal Open For edit jobs
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [type, setType] = useState('');
	const [pay, setPay] = useState('');
	const [startingDate, setStartingDate] = useState('');
	const [state, setState] = useState('');
	const [city, setCity] = useState('');
	const [skills, setSkills] = useState([]);

	const dispatch = useDispatch();
	const { uid } = useSelector(state => state.authUser);

	const handleEditPostModal = () => {
		setEditPostModal(!editPostModal);
	};

	const handleEditJobs = (id, obj) => {
		setEditPostModal(true);
		setEditID(id);
		setTitle(obj?.title);
		setDescription(obj?.description);
		setStartingDate(obj?.starting_from);
		setPay(obj?.hourly_pay);
		setType(obj?.type);
		setState(obj?.state);
		setCity(obj?.city);
		setSkills(obj?.skills);
	};

	const handleUpdateJobForm = e => {
		e.preventDefault();
		let obj = {
			title: title,
			description: description,
			hourly_pay: pay,
			starting_from: startingDate,
			type: type,
			state: state.toUpperCase(),
			city: city.toUpperCase(),
			skills: skills,
		};

		dispatch(updateJob(editID, obj));
		setEditPostModal(false);
		setTitle('');
		setDescription('');
		setStartingDate('');
		setPay('');
		setType('');
		setState('');
		setCity('');
		setSkills([]);
	};
	const defaultSKillsProps = {
		options: skills.map(insti => {
			return insti;
		}),
		getOptionLabel: option => option,
	};

	useEffect(() => {
		dispatch(fetchMyJobs(uid));
	}, []);

	return (
		<Container fluid className='myJobsMain'>
			<h3 className='text-center mb-4 pt-2'>
				<span style={{ borderBottom: '3px dashed black' }}>JOBS</span>
			</h3>
			<Row>
				{myJobs && myJobs.length > 0 ? (
					myJobs.map((ele, index) => {
						return (
							<Col lg='6' xl='4'>
								<Card className='mb-4 mt-3 mb-xl-0'>
									<CardBody>
										<div key={index} className='jobCardSet'>
											<div className='d-flex justify-content-end'>
												<span
													className={
														ele.status == 'active'
															? 'badge p-2 badge-success'
															: 'badge p-2 badge-danger'
													}
												>
													{ele.status}
												</span>
											</div>
											<MyJobsCard ele={ele} />
											{ele.status == 'active' ? (
												<div className='d-flex align-items-center justify-content-between mt-2'>
													<i
														onClick={() =>
															handleEditJobs(
																ele.id,
																ele
															)
														}
														className='fas fa-edit fa-lg pointer'
													></i>
													<Link
														to={`/job-activity/${ele.id}`}
													>
														<Button color='success'>
															View Job Activity
														</Button>
													</Link>
												</div>
											) : null}
										</div>
									</CardBody>
								</Card>
							</Col>
						);
					})
				) : (
					<Col>
						<h2 className='text-center mt-3'>
							You Have No Jobs Yet
						</h2>
					</Col>
				)}
			</Row>
			{/*  Modal For Edit My Jobs */}
			<Modal isOpen={editPostModal} toggle={handleEditPostModal} centered>
				<ModalBody>
					<div className='d-flex justify-content-between align-items-center'>
						<div>
							<h5>Edit Job</h5>
						</div>
						<div>
							<i
								onClick={handleEditPostModal}
								className='fas fa-times'
							></i>
						</div>
					</div>
					<Form className='editForm'>
						<FormGroup className='mb-1'>
							<Label className='labelText' for='title'>
								Title:
							</Label>
							<Input
								type='text'
								className='form-control focushide'
								required
								value={title}
								onChange={e => setTitle(e.target.value)}
							/>
						</FormGroup>
						<FormGroup className='mb-1'>
							<Label className='labelText' for='title'>
								Description:
							</Label>
							<Input
								className='jobdesc form-control focushide'
								value={description}
								type='textarea'
								required
								onChange={e => setDescription(e.target.value)}
								placeholder='write your thoughts...'
								rows={3}
							/>
						</FormGroup>
						<FormGroup className='mb-1'>
							<Label className='labelText' for='title'>
								Type Of Work:
							</Label>
							<Input
								type='text'
								className='form-control focushide'
								required
								value={type}
								onChange={e => setType(e.target.value)}
							/>
						</FormGroup>
						<Row className='mb-1'>
							<Col md={6} xs={12}>
								<FormGroup>
									<Label className='labelText' for='title'>
										Hourly Pay:
									</Label>
									<Input
										type='number'
										className='form-control focushide'
										required
										value={pay}
										onChange={e => setPay(e.target.value)}
									/>
								</FormGroup>
							</Col>
							<Col md={6} xs={12}>
								<FormGroup>
									<Label className='labelText' for='title'>
										Starting From:
									</Label>
									<Input
										type='date'
										className='form-control focushide'
										required
										value={startingDate}
										onChange={e =>
											setStartingDate(e.target.value)
										}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row className='mb-1'>
							<Col md={6} xs={12}>
								<FormGroup>
									<Label className='labelText' for='title'>
										State:
									</Label>
									<Input
										type='text'
										className='form-control focushide'
										required
										value={state}
										onChange={e => setState(e.target.value)}
									/>
								</FormGroup>
							</Col>
							<Col md={6} xs={12}>
								<FormGroup>
									<Label className='labelText' for='title'>
										City:
									</Label>
									<Input
										type='text'
										className='form-control focushide'
										required
										value={city}
										onChange={e => setCity(e.target.value)}
									/>
								</FormGroup>
							</Col>
						</Row>
						<FormGroup className='mb-1'>
							<Label className='labelText' for='title'>
								Skills:
							</Label>
							<Autocomplete
								{...defaultSKillsProps}
								autoComplete
								disableClearable={true}
								disableListWrap
								value={skills}
								onChange={(e, val) => {
									setSkills(val);
									// if (skills.length >= 5) {
									// 	toast.warn(
									// 		'Your Limit To Add Skill Filled'
									// 	);
									// } else {
									// 	setSkills(val);
									// }
								}}
								multiple
								id='size-small-filled-multi'
								size='small'
								freeSolo
								renderInput={params => (
									<TextField {...params} />
								)}
							/>
						</FormGroup>
					</Form>

					<div className='d-flex mt-4 justify-content-end'>
						<Button
							className='bg-primary border-0 mr-1'
							onClick={handleUpdateJobForm}
						>
							Save
						</Button>{' '}
						<Button color='secondary' onClick={handleEditPostModal}>
							Cancel
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default MyJobs;
