import moment from 'moment';
import React from 'react';

const SendChat = props => {
	return (
		<>
			<div className='sendChatOuter'>
				<div className='send-msg'>
					{props.msgText ? (
						<p className='send-msg-texts'>{props.msgText}</p>
					) : null}

					{props.messageFile_url ? (
						<p className='m-0'>
							<a href={props.messageFile_url} target='_blank'>
								view File
							</a>
						</p>
					) : null}
					<p className='send-msg-time'>
						{moment.unix(props.created_at.seconds).fromNow()}
					</p>
				</div>
			</div>
		</>
	);
};

export default SendChat;
