import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import match from '../assets/img/match.jpg';
import Four from '../assets/img/four.png';
import future from '../assets/img/future.jpg';
import HomePic from '../assets/img/new-Home.jpg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function NewHome() {
	let history = useHistory();

	return (
		<>
			<Container>
				<Row className='align-self-center mt-5'>
					<Col sm='2' className='order-2 order-md-1'>
						<div className='d-flex flex-column bg-danger h-100 justify-content-center'>
							<p className='font-weight-bold text-dark text-center'>
								STUDENT ATHLETE
							</p>
						</div>
					</Col>
					<Col sm='8' className='order-1 order-md-2 px-md-0 mx-md-0'>
						<div className='homeMain '>
							<Row className='align-items-center '>
								<Col xs={4} md={5} className='pr-0'>
									<div>
										<img
											src={match}
											className='img-fluid '
											alt=''
										/>
									</div>
								</Col>
								<Col xs={4} md={2} className='p-0'>
									<div>
										<img
											src={Four}
											className='img-fluid '
											alt=''
										/>
									</div>
								</Col>
								<Col xs={4} md={5} className='pl-0'>
									<div>
										<img
											src={future}
											className='img-fluid '
											alt=''
										/>
									</div>
								</Col>
							</Row>
							<Row className='d-flex justify-content-center align-items-center HomePic'>
								{/* <Button
										size='md'
										color='primary'
										className='HomePic_Button float-right mr-3'
										onClick={() => {
											history.push('/home');
										}}
									>
										ENTER
									</Button> */}
								<Link
									to={'/home'}
									className='btn btn-primary HomePic_Button float-right mr-3'
								>
									ENTER
								</Link>
								{/* <Link to={`/home`}> */}
								{/* </Link> */}

								<img
									src={HomePic}
									className='img-fluid'
									alt='homePic'
								/>
							</Row>
						</div>
					</Col>
					<Col sm='2' className='order-3 order-md-3'>
						<div className='d-flex flex-column bg-danger h-100 justify-content-center'>
							<p className='font-weight-bold text-white text-center'>
								THANKS YOU
							</p>
						</div>
					</Col>
				</Row>
				{/* <Container>
				<div className='d-flex flex-column bg-danger justify-content-center'>
					<p className='my-auto font-weight-bold text-white my-auto'>
						ThankYou
					</p>
				</div>
			</Container> */}
			</Container>
		</>
	);
}

export default NewHome;
