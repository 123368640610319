import firebase from '../../config/firebase';

export const fetchCollegeStates = () => async dispatch => {
	firebase
		.firestore()
		.collection('states')
		.orderBy('name', 'asc')
		.onSnapshot(query => {
			var tempStates = [];
			query.forEach(doc => {
				tempStates.push({ id: doc.id, ...doc.data() });
			});
			dispatch({
				type: 'GET_COLLEGE_STATES',
				payload: tempStates,
			});
		});
};

export const fetchSchoolStates = () => async dispatch => {
	firebase
		.firestore()
		.collection('school_states')
		.orderBy('name', 'asc')
		.onSnapshot(query => {
			var tempStates = [];
			query.forEach(doc => {
				tempStates.push({ id: doc.id, ...doc.data() });
			});
			dispatch({
				type: 'GET_SCHOOL_STATES',
				payload: tempStates,
			});
		});
};

export const getCollegeCity = state => async dispatch => {
	firebase
		.firestore()
		.collection('test-colleges-universities')
		.where('state', '==', state)
		.orderBy('city', 'asc')
		.onSnapshot(query => {
			let temp = [];
			query.forEach(doc => {
				temp.push({
					...doc.data(),
				});
			});
			dispatch({ type: 'GET_CITY_BY_STATE', payload: temp });
		});
};

export const getSchoolCounty = state => async dispatch => {
	firebase
		.firestore()
		.collection('test_schools')
		.where('state', '==', state)
		.orderBy('county', 'asc')
		.onSnapshot(query => {
			let temp = [];
			query.forEach(doc => {
				temp.push({
					...doc.data(),
				});
			});
			dispatch({ type: 'GET_COUNTY_BY_STATE', payload: temp });
		});
};

export const fetchSchoolInstitute = (state, college) => async dispatch => {
	firebase
		.firestore()
		.collection('test_schools')
		.where('state', '==', state)
		.where('county', '==', college)
		.onSnapshot(query => {
			let temp = [];
			query.forEach(doc => {
				temp.push({
					...doc.data(),
				});
			});
			dispatch({ type: 'NEW_SCHOOLS', payload: temp });
		});
};

export const fetchCollegeInstitute = (state, college) => async dispatch => {
	firebase
		.firestore()
		.collection('test-colleges-universities')
		.where('state', '==', state)
		.where('city', '==', college)
		.onSnapshot(query => {
			let temp = [];
			query.forEach(doc => {
				temp.push({
					...doc.data(),
				});
			});
			dispatch({ type: 'NEW_COLLEGE_UNIVERSITIES', payload: temp });
		});
};
